import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { galleryCollectionRef } from "../../firebase/fire-app";


const AddImageSectionPopup = () => {
    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onHideModal = () => {
        setShowModal(!showModal);
    }
    const onFormSubmit = (data, e) => {
        data.images = []
        galleryCollectionRef.doc('images').collection('images').add(data).then(() => {
            setShowModal(false)
        })
    }


    return (
        <>

            <button onClick={() => setShowModal(true)} className="btn-block btn btn-danger mt-4">ADD NEW SECTION</button>

            <Modal
                show={showModal}
                onHide={onHideModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-little-vcenter'>
                        Add image section
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <form onSubmit={handleSubmit(onFormSubmit)} >
                                <div className="mb-3">
                                    <InputWrapperComponent required htmlFor={"title"} label="Title">
                                        <input {...register("title", { required: true })} type="text" className="form-control" name="title" id="title" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"sl"} label="Serial:">
                                        <input {...register("sl", { required: true })} type="sl" className="form-control" name="sl" id="sl" />
                                    </InputWrapperComponent>

                                    <div className="row">
                                        <div className="col-md-6 offset-md-3">
                                            <button type="submit" className="btn btn-primary mb-4 mr-3">Add Section</button>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddImageSectionPopup;



const InputWrapperComponent = ({ children, label, htmlFor, required }) => {
    return (
        <div className="mb-3 row">
            <label htmlFor={htmlFor} className="col-sm-3 col-form-label">{label} {required && <span className="text-danger font-bold">*</span>}</label>
            <div className="col-sm-9">
                {children}
            </div>
        </div>
    )
}