import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { messagesCollectionRef } from '../../firebase/fire-app';
import MessageComponent from './MessageComponent';

const PageMessages = () => {
    const [messages, loading, error] = useCollectionData(messagesCollectionRef.orderBy('createdAt', 'desc'), {idField: 'id'});
    messagesCollectionRef.orderBy('createdAt', 'desc').get()

    // messagesCollectionRef.orderBy('date', 'desc').get().then(()=>{
    //     console.log('hello');
    // })
    return (
        <>
            <section className=''>
                <div className="container-fluid">
                <h3 className="text-left font-bold mb-4">Messages</h3>

                    <div className="row">
                        {!loading && messages.map(item => <MessageComponent data={item} key={item.id} /> )}
                    </div>
                </div>
            </section>

        </>
    );
};

export default PageMessages;