import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import fireApp, { eventsCollectionRef, writersCollectionRef } from '../../firebase/fire-app';
import "./events.scss";


const PageAllEvents = () => {
    const [allEvents, loading, error] = useCollectionData(eventsCollectionRef.orderBy('createdAt', 'desc'), { idField: 'id' });



    return (
        <>
            <section className="py-5 bg-primary text-center">
                <h1 className="font-54 font-bold text-white">
                    Events
                </h1>
                {/* <button onClick={logBtn} className="btn btn-danger">Log</button> */}
            </section>
            <section className="py-5">

                <div className="container">
                    {allEvents && allEvents.length > 0 && allEvents.map(item => <EventCard data={item} key={item.id} />)}


                    {/* {events && events.map(item => <EventCard data={item} key={item.id} />)} */}
                </div>
            </section>
        </>
    );
};

export default PageAllEvents;



const EventCard = ({ data }) => {
    const imageLink = `https://firebasestorage.googleapis.com/v0/b/theworldofpeaceorg.appspot.com/o/insights%2FKnX2ZU4hQxn9wCQtmUbo%2Fbangladesh-online-food-delivery-market-where-is-it-heading.png?alt=media&token=3c82cec6-349c-4188-ac0b-5862884798b3`;
    const onDeleteEvent = () => {
        eventsCollectionRef.doc(data.id).delete().then(()=>{
            alert("The event is deleted!")
        });
    }
    return (
        <>
            <div className="row mb-4">
                <div className="col-md-8 offset-md-2">
                    <div className="event-card isClickable px-5 py-5">
                        <div className="mb-3 ">
                            <h2 className="font-bold font-26">{data.name}</h2>
                            <h6 className="f"><i>February 22, 2022</i></h6>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="">
                                    {/* <img src="/workshop.jpeg" alt="" /> */}
                                    {/* <Image src={imageLink} width={100} height={60} className="img-fluid" alt={data.name} /> */}
                                    <img src={data.img} className='img-fluid' alt="Event" />
                                </div>
                            </div>
                        </div>
                        <p className="font-18 mb-3">
                            {data.brief}
                        </p>
                        <button onClick={onDeleteEvent} className="btn mr-3 btn-danger">Delete</button>
                        <a href={data.fbEvent} className="btn btn-primary mr-3" target="_blank" rel="noopener noreferrer">Event Link</a>
                        <a href={data.registrationLink} className="btn btn-primary mr-3" target="_blank" rel="noopener noreferrer">Register Now</a>
                    </div>
                </div>
            </div>
        </>
    )
}