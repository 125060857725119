import { useState } from 'react';
import { Modal } from 'antd';
import { Button } from 'react-bootstrap';


const EditSlugTitle = ({  edit,  blog, setBlog }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentValue, setCurrentValue] = useState(null);

    const onValueChange = (e) => {
        setCurrentValue(e.target.value)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        if(edit === "Title"){
            setBlog({ ...blog, title: currentValue})
        }
        if(edit === "Slug"){
            const currentUpdate = currentValue.replace(" ", "-")
            setBlog({ ...blog, slug: currentUpdate})
        }
        setIsModalVisible(false);

    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <>
            <Button variant="dark" size="sm" onClick={showModal}>
                Edit {edit}
            </Button>
            <Modal title={`Edit ${edit}`} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {/* <small>{currentValue} </small> */}
                        <input onChange={onValueChange} type="text" placeholder={`new ${edit}`} className="form-control" />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default EditSlugTitle;