import { withRouter, useHistory, useLocation, Link } from "react-router-dom";
import "firebase/auth"
import GoogleIcon from "./google.png"
import "./LoginPage.scss";
import { useAuth } from "../../Hooks/useAuth";
import fireApp, { userInfoCollectionRef } from "../../firebase/fire-app";
import { useForm } from "react-hook-form";
import { useDocumentData } from "react-firebase-hooks/firestore";
import PasswordResetPopup from "./PasswordResetPopup";
import AdminRegisterPopup from "./AdminRegisterPopup";
import SuccessRegistrationPopup from "./SuccessRegistrationPopup";


const LoginPage = () => {
    const [admins, loading, error] = useDocumentData(userInfoCollectionRef.doc('admins'));

    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { currentUser, setCurrentUser, hasAccess, handleGoogleSignIn, signOut, basicLog } = useAuth();

    const signInHandler = () => {
        return handleGoogleSignIn()
            .then((data) => {
                console.log("loggedin", data);
                history.replace(from)
            })
    }

    const onFormSubmit = (data, e) => {
        console.log(data);

        const email = data.email;
        const password = data.password;

        if (admins) {
            if (admins.admins.indexOf(email) > -1) {
                fireApp.auth().signInWithEmailAndPassword(email, password).then((userCredential) => {
                    const user = userCredential.user;
                    console.log(user);
                    setCurrentUser(user);
                    // setRegistered(true);
                    history.replace(from)
                })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        alert(errorMessage);
                    })
            } else {
                alert("You are not an admin yet :'(")
            }
        }



    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <h2 className=" mb-3 mt">
                            Login Here
                        </h2>
                        {currentUser && !hasAccess && <div className="mt-4">
                            <h5 className="text-danger" >
                                Your registration is successful! But, you are not approved as an admin yet :'()'
                            </h5>
                        </div> }
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-7">

                                <div className="py-4 pb-5 px-5 login-layout">
                                    <h2 className="text-center">{currentUser && currentUser.name}</h2>

                                    <button onClick={signInHandler} className="btn custom-auth-btn btn-block py-3 mb-3 mt-3">
                                        <img src={GoogleIcon} alt="" />
                                        Continue with Google
                                    </button>
                                    <h3 className="text-center my-3">OR</h3>
                                    <div className="">
                                        <form onSubmit={handleSubmit(onFormSubmit)} >
                                            <div className="mb-3 row">
                                                <label htmlFor="inputEmail" className="col-sm-3 col-form-label">Email</label>
                                                <div className="col-sm-9">
                                                    <input name="email" type="email" {...register("email", { required: true })} className="form-control" id="email" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="inputPassword" className="col-sm-3 col-form-label">Password</label>
                                                <div className="col-sm-9">
                                                    <input name="password" type="password" {...register("password", { required: false })} className="form-control" id="password" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-3"></div>
                                                <div className="col-sm-9 ">
                                                    <div className="form-check">
                                                        <input type="checkbox" value="" id="flexCheckChecked" checked className="form-check-input" />
                                                        <label htmlFor="flexCheckChecked" className="form-check-label">
                                                            Keep me singed in!
                                                        </label>
                                                    </div>
                                                    <div className=" mt-4 align-er">
                                                        <button type="submit" className="btn btn-primary mb-4 me-3">Login</button> <br />

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <span className="font-18">Forgot password? <PasswordResetPopup /> </span><br /> <br />
                                        <span className="font-18">If you are a new user, </span> <AdminRegisterPopup />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

{currentUser && !hasAccess &&  <SuccessRegistrationPopup/>}
            </div>


        </>
    );
};

export default withRouter(LoginPage);
