import React, { useState } from 'react';


import CustomCK from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'



const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'alignment',
        'fontSize',
        'fontColor',
        'bold',
        'italic',
        'subscript',
        'superscript',
        'underline',
        'horizontalLine',
        'link',
        '|',
        'outdent',
        'indent',
        'numberedList',
        'bulletedList',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
    ],
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'toggleImageCaption'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ]
    },

};

const RichTextEditor = ({ returnText, defaultText = '', title }) => {
    const [editorText, setEditorText] = useState(defaultText)

    return (
        <>
            <h4>{title}</h4>
            <CKEditor
                editor={CustomCK}
                data={editorText}
                config={editorConfiguration}
                // onReady={editor => }
                // onReady={editor => {
                //     // You can store the "editor" and use when it is needed.
                //     console.log('Editor is ready to use!', editor);
                // }}

                onChange={(event, editor) => {
                    const data = editor.getData();
                    returnText(data)
                    // console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                    const data = editor.getData();
                    returnText(data)
                }}
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                }}
            />
        </>
    );
};

export default RichTextEditor;