import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { adminRegistrationCollectionRef, userMetadataCollectionRef } from "../../firebase/fire-app";


const AdminDetailsPopup = ({ email, approveAdmin }) => {
    const [showModal, setShowModal] = useState(false);
    const [info, setInfo] = useState(null);
    const [metaInfo, setMetaInfo] = useState(null)

    const onHideModal = () => {
        setShowModal(!showModal);
    }
    const [registeredAdmins, loading, error] = useCollectionData(adminRegistrationCollectionRef.where('email', '==', email), { idField: 'id' });
    const [metaAdmins, metaLoading, metaError] = useCollectionData(userMetadataCollectionRef.where('userEmail', '==', email), { idField: 'id' });
    useEffect(() => {
        if (registeredAdmins && registeredAdmins.length > 0) {
            setInfo(registeredAdmins[0]);
        }
    }, [registeredAdmins])
    useEffect(() => {
        if (metaAdmins && metaAdmins.length > 0) {
            setMetaInfo(metaAdmins[0]);
        }
    }, [metaAdmins])


    return (
        <>
            <Modal
                show={showModal}
                onHide={onHideModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-little-vcenter'>
                        Admin Details: {info && info.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {info ?
                        <div className="px-5 py-4">
                            <p>Name: {info.name}</p>
                            <p>Email: {info.email}</p>
                            <p>Phone: {info.phone}</p>
                            <p>Job: {info.designation}, {info.organization}</p>
                            <p>Country: {info.country}</p>
                            <p>Affiliated Projects: {info.projects}</p>
                            <button onClick={() => approveAdmin(email)} className="btn btn-sm btn-danger">APPROVE</button>
                        </div> :
                        metaInfo ? <div className="px-5 py-4">
                            <p>Name: {metaInfo.fullName}</p>
                            <p>Email: {metaInfo.userEmail}</p>
                            <p>Phone: {metaInfo.userPhone}</p>
                            <p>Job: {metaInfo.jobTitle}, {metaInfo.organization}</p>
                            <p>Country: {metaInfo.country}</p>
                            <p>Affiliated Projects: {metaInfo.projects}</p>
                            <button onClick={() => approveAdmin(email)} className="btn btn-sm btn-danger">APPROVE</button>
                        </div> : ""
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <button onClick={() => setShowModal(true)} className="btn-sm ml-3 btn btn-primary">
                SHOW DETAILS
            </button>
        </>
    );
};

export default AdminDetailsPopup;