import { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import partnersData from "../../data/partners";
import { programsCollectionRef } from "../../firebase/fire-app";
import PartnerCard from "./PartnerCard";

const ViewAllPrograms = () => {
    const [allPartners, setAllPartners] = useState(null);
    const [country, setCountry] = useState("Bangladesh");
    const [fetchData, loading, error] = useCollectionData(programsCollectionRef, { idField: "id" });

    useEffect(() => {
        if (fetchData && fetchData.length > 0) {
            const filteredPartners = fetchData.filter(partner => partner.country === country);
            setAllPartners(filteredPartners);
        }
    }, [fetchData, country])
    const changeCountry = (data) => {
        setCountry(data)
    }
    return (
        <>
            <div className="btn-group mb-4">
                <button type="button" onClick={() => changeCountry('Sri-Lanka')} className={`btn btn-${country === "Sri-Lanka" ? "primary" : "secondary"}`}>Sri-Lanka</button>
                <button type="button" onClick={() => changeCountry('Bangladesh')} className={`btn btn-${country === "Bangladesh" ? "primary" : "secondary"}`}>Bangladesh</button>
            </div>
            <div className="row">
                {allPartners && allPartners.map((data, index) => <PartnerCard data={data} key={data.id} />)}
            </div>
        </>

    );
};

export default ViewAllPrograms;