import { Route, Switch } from "react-router-dom"
import AntdLayout from '../Components/AntdLayout/AntdLayout';
import { useAuth } from "../Hooks/useAuth";
import PageAdmins from "./admins/PageAdmins";
import AppointmentsPage from "./Appointments/AppointmentsPage";
import AddNewBlog from "./Blogs/AddNewBlog";
import EditBlog from "./Blogs/EditBlog";
// import EditBlog from "./Blogs/EditBlog";
import PageBlogs from "./Blogs/PageBlogs";
import AddNewCase from "./CaseStudies/AddNewCase";
// import EditCase from "./CaseStudies/EditCase";
import PageCaseStudies from "./CaseStudies/PageCaseStudies";
import PageAddNewEvent from "./Events/PageAddNewEvent";
import PageAllEvents from "./Events/PageAllEvents";
import GalleryPage from "./gallery/GalleryPage";
import AddNewInsight from "./Insights/AddNewInsight";
import EditInsight from "./Insights/EditInsight";
import PageInsights from "./Insights/PageInsights";
import LoginPage from "./LoginPage/LoginPage";
import PageMessages from "./messages/PageMessages";
import AddNewNews from "./News/AddNewNews";
import EditNews from "./News/EditNews";
import PageNews from "./News/PageNews";
import AddPartner from "./Partners/AddPartner";
import EditPartnerInfo from "./Partners/EditPartnerInfo";
import ViewAllPartners from "./Partners/ViewAllPartners";
import AddProgram from "./programs/AddProgram";
import EditProgramInfo from "./programs/EditProgramInfo";
import ViewAllPrograms from "./programs/ViewAllPrograms";
import PageAddResources from "./resources/PageAddResources";
import PageAllResources from "./resources/PageAllResources";
import TestPage from "./TestPage";
import AddWriter from "./Writers/AddWriter";
import AllWriters from "./Writers/AllWriters";
import EditWriter from "./Writers/EditWriter";

const Main = () => {
    const { hasAccess } = useAuth()
    const giveAccess = hasAccess;
    return (
        <>
            <AntdLayout>
                <Switch>
                    {giveAccess && <Route exact path="/">
                        <TestPage />
                    </Route>}
                    {/* services */}
                    {/* {giveAccess &&<Route exact path="/services">
                        <AllServices/>
                    </Route>} */}
                    {/* login */}
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
            

                    {/* blogs */}
                    {giveAccess && <Route exact path="/blogs">
                        <PageBlogs />
                    </Route>}
                    {giveAccess && <Route exact path="/blog/add">
                        <AddNewBlog />
                    </Route>}
                    {giveAccess && <Route exact path="/blog/edit/:id">
                        <EditBlog />
                    </Route>}

                    {/* insights */}
                    {giveAccess && <Route exact path="/insights">
                        <PageInsights />
                    </Route>}
                    {giveAccess && <Route exact path="/insight/add">
                        <AddNewInsight />
                    </Route>}
                    {giveAccess && <Route exact path="/insight/edit/:id">
                        <EditInsight />
                    </Route>}

                    {/* news */}
                    {giveAccess && <Route exact path="/news">
                        <PageNews />
                    </Route>}
                    {giveAccess && <Route exact path="/news/add">
                        <AddNewNews />
                    </Route>}
                    {giveAccess && <Route exact path="/news/edit/:id">
                        <EditNews />
                    </Route>}

                    {/* Partners */}
                    {giveAccess && <Route exact path="/partners">
                        <ViewAllPartners />
                    </Route>}
                    {giveAccess && <Route exact path="/partners/add">
                        <AddPartner />
                    </Route>}
                    {giveAccess && <Route exact path="/partners/edit/:partnerID">
                        <EditPartnerInfo />
                    </Route>}

                    {/* programs */}
                    {giveAccess && <Route exact path="/programs">
                        <ViewAllPrograms />
                    </Route>}
                    {giveAccess && <Route exact path="/programs/add">
                        <AddProgram />
                    </Route>}
                    {giveAccess && <Route exact path="/programs/edit/:programID">
                        <EditProgramInfo />
                    </Route>}

                    {/* gallery */}
                    {giveAccess && <Route exact path="/gallery">
                        <GalleryPage />
                    </Route>}
                   

                    {/* news */}
                    {giveAccess && <Route exact path="/events">
                        <PageAllEvents />
                    </Route>}
                    {giveAccess && <Route exact path="/events/add">
                        <PageAddNewEvent />
                    </Route>}
                    {giveAccess && <Route exact path="/events/edit/:id">
                        <EditNews />
                    </Route>}
                    {/* resources */}
                    {giveAccess && <Route exact path="/resources">
                        <PageAllResources />
                    </Route>}
                    {giveAccess && <Route exact path="/resources/add">
                        <PageAddResources />
                    </Route>}
                    {giveAccess && <Route exact path="/resources/edit/:id">
                        <EditNews />
                    </Route>}

                    {/* case */}
                    {giveAccess && <Route exact path="/case-studies">
                        <PageCaseStudies />
                    </Route>}
                    {giveAccess && <Route exact path="/case-studies/add">
                        <AddNewCase />
                    </Route>}
                    {/* {giveAccess && <Route exact path="/case-studies/edit/:id">
                        <EditCase />
                    </Route>} */}

                    {/* writers */}
                    {giveAccess && <Route exact path="/writers">
                        <AllWriters />
                    </Route>}
                    {giveAccess && <Route exact path="/writers/add">
                        <AddWriter />
                    </Route>}
                    {giveAccess && <Route exact path="/writers/edit/:writerId">
                        <EditWriter />
                    </Route>}

                    {/* bookings */}
                    {giveAccess && <Route exact path="/appointments">
                        <AppointmentsPage />
                    </Route>}
                    {/* admins */}
                    {giveAccess && <Route exact path="/admins">
                        <PageAdmins />
                    </Route>}
                    {/* message */}
                    {giveAccess && <Route exact path="/messages">
                        <PageMessages />
                    </Route>}
                    <Route path="*">
                        <LoginPage />
                    </Route>
                </Switch>
            </AntdLayout>
        </>
    );
};

export default Main;