import React, { useEffect } from 'react';
import { useState } from 'react';
import{newsCollectionRef } from '../../firebase/fire-app';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Spinner } from 'react-bootstrap';
import fireApp from '../../firebase/fire-app';
import { useBlogs } from '../../Hooks/useBlogs';
import AddNewCategory from './AddNewCategory';
import { Switch } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {FaGlobe} from "react-icons/fa"
import EditSlugTitle from './EditSlugTitle';


import CustomCK from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'alignment',
        'fontSize',
        'fontColor',
        'bold',
        'italic',
        'subscript',
        'superscript',
        'underline',
        'horizontalLine',
        'link',
        '|',
        'outdent',
        'indent',
        'numberedList',
        'bulletedList',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
    ],
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'toggleImageCaption'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ]
    },

};


class MyUploadAdapter {
    constructor(loader, blogSlug) {
        this.loader = loader;
        this.blogSlug = blogSlug;

    }

    upload() {
        return this.loader.file.then(
            file =>
                new Promise((resolve, reject) => {
                    console.log("log", file.name)
                    let storage = fireApp.storage().ref();
                    let newsRef = storage.child(`news/${this.blogSlug}/${file.name}`)
                    newsRef
                        .put(file).then((snapshot) => {
                            snapshot.ref.getDownloadURL().then(res => {
                                resolve({
                                    default: res
                                });
                            })
                        })
                })
        );
    }
}

const EditNews = () => {
    const blogText = `<h1>Hello!</h1>`;
    // const [blog, setBlog] = useState({})
    const [loading, setLoading] = useState(true);
    const {categories} = useBlogs()



    const [blogContent, setBlogContent] = useState(blogText);
    const [blogExists, setBlogExists] = useState(false)
    const { id } = useParams();
    console.log(id);
    const [blog, setBlog] =useState(null);


    const { register, handleSubmit, watch, errors } = useForm();
    useEffect(() => {
        newsCollectionRef.doc(id).get().then(response => {
            console.log(response.data());
            let blogData = response.data();
            blogData.lastUpdated = new Date()
            setBlog(blogData);
            setLoading(false);
        })
    }, [])
    const onTagAdded = (data, e) => {
        const newTag = data.tag;
        let allTags = [...blog.tags, newTag];
        setBlog({ ...blog, tags: allTags })
        e.target.reset();
    }
    const onCategoryChanged = (e) => {
        const cat = e.target.value;
        setBlog({ ...blog, category: cat })
    }
    const onFeaturedBlog =(checked)=>{
        setBlog({ ...blog, featured: checked})
    }
    const onStatusChanged = (e) => {
        const currentStat = e.target.value;
        setBlog({ ...blog, status: currentStat })
    }
    const onFeaturedImgChange = (e) => {
        let storageRef = fireApp.storage().ref();
        let blogStorage = storageRef.child(`news/${id}/${id}`);
        const imgFile = e.target.files[0];
        blogStorage
            .put(imgFile).then((snapshot) => {
                snapshot.ref.getDownloadURL().then(res => {
                    setBlog({ ...blog, featuredImage: res })
                })
            })
    }
    const saveBlog = () => {
        newsCollectionRef.doc(id).set({...blog}, {merge:true}).then(res => {
            setBlog({...blog})
        })
    }
    return (
        <>
            {/* <div className="bg-light py-4">
                <h4 className="h4 text-center">{slug} </h4>
            </div> */}
            {loading ? <Spinner animation="grow" /> :
                <div className="container pb-5">
                    <button onClick={()=> console.log(blog)} className="btn btn-danger">log</button>
                    <h4 className="text-center mb-3 text-primary">{blog.title} &nbsp; &nbsp;&nbsp; <EditSlugTitle blog={blog} setBlog={setBlog} edit="Title" />  </h4>
                    <h4 className="text-center mb-4 text-dark"><FaGlobe/> <small>{blog.slug}  </small>&nbsp; &nbsp;&nbsp; <EditSlugTitle blog={blog} setBlog={setBlog} edit="Slug" />  </h4>

                    <div className="row">
                        <div className="col-md-8">
                            <CKEditor
                                editor={CustomCK}
                                config={editorConfiguration}
                                data={blog.content}
                                onReady={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader, id);
                                    };
                                }}
                                // onReady={editor => {
                                //     // You can store the "editor" and use when it is needed.
                                //     console.log('Editor is ready to use!', editor);
                                // }}
                                onInit={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader, id);
                                    };
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBlog({ ...blog, content: data })
                                    // console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="editor-sidebar py-4">
                                <h5 className="text-center">Status</h5>
                                <p className="text-center" >{blog.status ? blog.status : "not selected"}</p>
                                <select onChange={onStatusChanged} name="category" className="custom-select">
                                    <option value="Draft">Set Status</option>
                                    <option value="Draft">Draft</option>
                                    <option value="Published">Published</option>
                                    <option value="Restricted">Restricted</option>
                                </select>
                                <p className="mt-3 font-weight-bold">Is everything okay? Want to save?</p>
                                <div className="text-center">
                                    <Button variant="danger" onClick={saveBlog} size="lg">&nbsp;&nbsp; Save &nbsp;&nbsp; </Button>
                                </div>
                            </div>
                            <div className="editor-sidebar py-4">
                                <h5 className="text-center">Featured Image</h5>
                                <div className="p-4 text-center">
                                    {blog.featuredImage ? <img className="img-fluid" src={blog.featuredImage} alt={blog.slug} /> : "*no featured image yet"}
                                </div>
                                <input type="file" onChange={onFeaturedImgChange} />
                            </div>
                            <div className="editor-sidebar py-4">
                                Featured news ? <span>
                                <Switch defaultChecked={blog.featured ? true : false} onChange={onFeaturedBlog} />
                                </span>
                            </div>
                            <div className="editor-sidebar py-4">
                                <h5 className="text-center">Tags</h5>
                                {blog.tags.map(item => <span className="badge badge-primary mr-2 p-2 mb-2">{"#" + item} </span>)}
                                <form className="mt-2" onSubmit={handleSubmit(onTagAdded)}>
                                    <div>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-8 p-0">
                                                <input placeholder="add new tag..." {...register("tag")} type="text" name="tag" className="form-control" />
                                            </div>
                                            <div className="col-4 p-0 text-center">
                                                <button type="submit" className="btn btn-sm btn-primary">
                                                    Add Tag
                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="editor-sidebar py-4">
                                <h5 className="text-center">Category:  </h5>
                                <p className="text-center" >{blog.category ? blog.category : "no category selected"}</p>
                                <div className="row">
                                    <select onChange={onCategoryChanged} name="category" className="custom-select">
                                    {categories && categories.newsCategories.map(item =>  <option value={item}>{item} </option>)}

                                    </select>
                                    <AddNewCategory/>
                                </div>
                            </div>
                            {/* <div className="editor-sidebar py-4">
                                <h5 className="text-center">Writer Details</h5>
                                <input type="text" name="author" placeholder="author name" className="form-control mb-3" />
                                <input type="text" placeholder="facebook" name="" className="form-control mb-3" />
                                <input type="text" placeholder="linkedIn" name="" className="form-control mb-3" />
                                <input type="text" placeholder="twitter" name="" className="form-control mb-3" />
                                <input type="text" placeholder="instagram" name="" className="form-control mb-3" />
                            </div> */}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default EditNews;