const firebaseConfig = {
    apiKey: "AIzaSyD1O_Y3DwitcBpZ_ecdi_-Ps1nKj90BiMQ",
    authDomain: "theworldofpeaceorg.firebaseapp.com",
    databaseURL:
        "https://theworldofpeaceorg-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "theworldofpeaceorg",
    storageBucket: "theworldofpeaceorg.appspot.com",
    messagingSenderId: "406428480792",
    appId: "1:406428480792:web:dcbc07c90330f6a1545a6d",
};

export default firebaseConfig;
