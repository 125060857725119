import React, { useEffect } from 'react';

import { useState } from 'react';
import { blogsCollectionRef, fireStoreDB, insightsCollectionRef } from '../../firebase/fire-app';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Spinner } from 'react-bootstrap';
import fireApp from '../../firebase/fire-app';
import { useBlogs } from '../../Hooks/useBlogs';
import AddNewCategory from './AddNewCategory';
import { Switch } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { FaGlobe } from "react-icons/fa"
import EditSlugTitle from './EditSlugTitle';
import EditWriters from '../Blogs/EditWriters';
import { updatedInsightCategories } from './insight-data';



import CustomCK from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'alignment',
        'fontSize',
        'fontColor',
        'bold',
        'italic',
        'subscript',
        'superscript',
        'underline',
        'horizontalLine',
        'link',
        '|',
        'outdent',
        'indent',
        'numberedList',
        'bulletedList',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
    ],
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'toggleImageCaption'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ]
    },

};


class MyUploadAdapter {
    constructor(loader, blogSlug) {
        this.loader = loader;
        this.blogSlug = blogSlug;

    }

    upload() {
        return this.loader.file.then(
            file =>
                new Promise((resolve, reject) => {
                    const name = file.name;
                    // const name = event.target.files[0].name;
                    const lastDot = name.lastIndexOf('.');

                    const fileName = name.replace(" ", "-").substring(0, lastDot);
                    const ext = name.substring(lastDot + 1);
                    // console.log("name: " + fileName + " ext: " + ext)

                    // outputfile.value = fileName;
                    // extension.value = ext;
                    let storage = fireApp.storage().ref();
                    let insightRef = storage.child(`insights/${this.blogSlug}/${fileName + "." + ext}`)
                    insightRef
                        .put(file).then((snapshot) => {
                            snapshot.ref.getDownloadURL().then(res => {
                                resolve({
                                    default: res
                                });
                            })
                        })
                })
        );
    }
}

const EditInsight = () => {
    const blogText = `<h1>Hello!</h1>`;
    // const [blog, setBlog] = useState({})
    const [loading, setLoading] = useState(true);
    const { categories } = useBlogs()



    const [blogContent, setBlogContent] = useState(blogText);
    const [blogExists, setBlogExists] = useState(false)
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [blogWriters, setBlogWriters] = useState([])


    const { register, handleSubmit, watch, errors } = useForm();
    useEffect(() => {
        insightsCollectionRef.doc(id).get().then(response => {
            console.log(response.data());
            let blogData = response.data();
            blogData.lastUpdated = new Date();
            setBlogWriters(blogData.writers)
            setBlog(blogData);
            setLoading(false);
        })
    }, [])

    const onTagAdded = (data, e) => {
        const newTag = data.tag;
        let allTags = [...blog.tags, newTag];
        setBlog({ ...blog, tags: allTags })
        e.target.reset();
    }
    const onShortDescriptionUpdated = (e) => {
        const newDescription = e.target.value;
        setBlog({ ...blog, shortDescription: newDescription })
    }
    const onExternalFileUpdated = (e) => {
        const newLink = e.target.value.trim();
        console.log(newLink);
        setBlog({ ...blog, externalFileLink: newLink })
    }
    const onExternalFileRemoved = () => {
        setBlog({ ...blog, externalFileLink: `` })
    }
    // sub-categories
    const [subCategories, setSubCategories] = useState(null);

    const onCategoryChanged = (e) => {
        const catId = e.target.value;
        if (catId == 0) {
            setBlog({ ...blog, category: updatedInsightCategories[0].name, subCategory: `` },);
            setSubCategories(updatedInsightCategories[0].categories);
        }
        if (catId == 1) {
            setBlog({ ...blog, category: updatedInsightCategories[1].name });
            setSubCategories(updatedInsightCategories[1].categories);
        }
    }
    const onSubCategoryChanged = (e) => {
        const subValue = e.target.value;
        setBlog({ ...blog, subCategory: subValue })
    }
    const onFeaturedBlog = (checked) => {
        setBlog({ ...blog, featured: checked })
    }
    const onStatusChanged = (e) => {
        const currentStat = e.target.value;
        setBlog({ ...blog, status: currentStat })
    }
    const onFeaturedImgChange = (e) => {
        const imgFile = e.target.files[0];
        const name = imgFile.name;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.replace(" ", "-").substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        //  console.log("name: "+fileName+" ext: "+ext)


        let storageRef = fireApp.storage().ref();



        let blogStorage = storageRef.child(`insights/${id}/${blog.slug + "." + ext}`);

        blogStorage
            .put(imgFile).then((snapshot) => {
                snapshot.ref.getDownloadURL().then(res => {
                    setBlog({ ...blog, featuredImage: res })
                })
            })
    }
    const saveBlog = () => {
        insightsCollectionRef.doc(id).set({ ...blog, writers: blogWriters ? blogWriters : [] }, { merge: true }).then(res => {
            setBlog({ ...blog })
        })
    }
    return (
        <>

            {loading ? <Spinner animation="grow" /> :
                <div className="container-fluid pb-5">
                    <div className="d-flex justify-content-between">
                        <h3 className="font-weight-bold  text-primary">{blog.title} &nbsp; &nbsp;&nbsp; <EditSlugTitle blog={blog} setBlog={setBlog} edit="Title" />  </h3>
                        <h4 className="font-weight-bold mb-5 text-dark"><FaGlobe /> <small>{blog.slug}  </small>&nbsp; &nbsp;&nbsp; <EditSlugTitle blog={blog} setBlog={setBlog} edit="Slug" />  </h4>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <h4 className="font-weight-bold">Content Brief :</h4>
                            <CKEditor
                                editor={CustomCK}
                                config={editorConfiguration}
                                data={blog.brief ? blog.brief : ""}
                                onReady={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader, id);
                                    };
                                }}
                                // onReady={editor => {
                                //     // You can store the "editor" and use when it is needed.
                                //     console.log('Editor is ready to use!', editor);
                                // }}
                                onInit={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader, id);
                                    };
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBlog({ ...blog, brief: data })
                                    // console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                            <h4 className="font-weight-bold mt-4">Main content :</h4>
                            <CKEditor
                                editor={CustomCK}
                                data={blog.content}
                                config={editorConfiguration}
                                onReady={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader, id);
                                    };
                                }}
                                // onReady={editor => {
                                //     // You can store the "editor" and use when it is needed.
                                //     console.log('Editor is ready to use!', editor);
                                // }}
                                onInit={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader, id);
                                    };
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBlog({ ...blog, content: data })
                                    // console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            {/* status */}
                            <div className="editor-sidebar py-4">
                                <h5 className="text-center">Status</h5>
                                <p className="text-center" >{blog.status ? blog.status : "not selected"}</p>
                                <select onChange={onStatusChanged} name="category" className="custom-select">
                                    <option value="Draft">Set Status</option>
                                    <option value="Draft">Draft</option>
                                    <option value="Published">Published</option>
                                    <option value="Restricted">Restricted</option>
                                </select>
                                <p className="mt-3 font-weight-bold">Is everything okay? Want to save?</p>
                                <div className="text-center">
                                    <Button variant="danger" onClick={saveBlog} size="lg">&nbsp;&nbsp; Save &nbsp;&nbsp; </Button>
                                </div>
                            </div>
                            {/* featured */}
                            <div className="editor-sidebar font-weight-bold py-4">
                                Featured insight ? <span>
                                    <Switch defaultChecked={blog.featured ? true : false} onChange={onFeaturedBlog} />
                                </span>
                            </div>
                            {/* category */}
                            <div className="editor-sidebar py-4">
                                {/* <h5 className="text-center font-weight-bold">Category:  </h5> */}
                                <p className="text-center font-weight-bold mb-4" >{blog.category ? blog.category : "no category selected"} {` > `} {blog.subCategory ? blog.subCategory : <span className="text-danger">sub-category not selected</span>} </p>
                                <p className="font-weight-bold mb-2">Category:</p>
                                <div className="row">
                                    <select onChange={onCategoryChanged} name="category" className="custom-select">
                                        {updatedInsightCategories && updatedInsightCategories.map(item => <option value={item.id}>{item.name} </option>)}
                                    </select>
                                </div>
                                <p className="font-weight-bold mb-2 mt-3">Sub category:</p>
                                <div className="row">
                                    <select onChange={onSubCategoryChanged} name="category" className="custom-select">
                                        {subCategories && subCategories.map(item => <option value={item}>{item} </option>)}
                                    </select>
                                </div>
                            </div>
                            {/* writers */}
                            {/* writers */}
                            <div className="editor-sidebar py-4">
                                <h5 className="text-center font-weight-bold">Writers</h5>
                                <div className="text-center">
                                    <EditWriters writers={blogWriters ? blogWriters : []} setWriters={setBlogWriters} />
                                </div>
                            </div>
                            {/* featured img */}
                            <div className="editor-sidebar py-4">
                                <h5 className="text-center font-weight-bold">Featured Image</h5>
                                <div className="p-4 text-center">
                                    {blog.featuredImage ? <img className="img-fluid" src={blog.featuredImage} alt={blog.slug} /> : "*no featured image yet"}
                                </div>
                                <input type="file" onChange={onFeaturedImgChange} />
                            </div>

                            {/* short description */}
                            <div className="editor-sidebar py-4">
                                <h5 className="font-weight-bold text-center">Short Description</h5>
                                {/* <p>{blog.shortDescription}</p> */}
                                <div className="short-description mt-4">
                                    <textarea defaultValue={blog.shortDescription} name="shortDescription" id="" cols="30" rows="10" className="form-control" onChange={onShortDescriptionUpdated}></textarea>
                                </div>
                            </div>
                            {/* externalFile */}
                            <div className="editor-sidebar py-4">
                                <h5 className="font-weight-bold text-center mb-3">External File</h5>
                                <a href={blog.externalFileLink} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary">Visit Now</a>
                                <button onClick={onExternalFileRemoved} className="btn btn-sm ml-4 btn-danger">Remove this file</button>
                                <div className="short-description mt-4">
                                    <textarea defaultValue={blog.externalFileLink} name="shortDescription" id="" cols="30" rows="4" className="form-control" onChange={onExternalFileUpdated}></textarea>
                                </div>
                            </div>
                            {/* tags */}
                            {/* <div className="editor-sidebar py-4">
                                <h5 className="text-center">Tags</h5>
                                {blog.tags.map(item => <span className="badge badge-primary mr-2 p-2 mb-2">{"#" + item} </span>)}
                                <form className="mt-2" onSubmit={handleSubmit(onTagAdded)}>
                                    <div>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-8 p-0">
                                                <input placeholder="add new tag..." {...register("tag")} type="text" name="tag" className="form-control" />
                                            </div>
                                            <div className="col-4 p-0 text-center">
                                                <button type="submit" className="btn btn-sm btn-primary">
                                                    Add Tag
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> */}

                            {/* <div className="editor-sidebar py-4">
                                <h5 className="text-center">Writer Details</h5>
                                <input type="text" name="author" placeholder="author name" className="form-control mb-3" />
                                <input type="text" placeholder="facebook" name="" className="form-control mb-3" />
                                <input type="text" placeholder="linkedIn" name="" className="form-control mb-3" />
                                <input type="text" placeholder="twitter" name="" className="form-control mb-3" />
                                <input type="text" placeholder="instagram" name="" className="form-control mb-3" />
                            </div> */}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default EditInsight;