import { Modal } from 'antd';
import { useState } from 'react';
import { AppointmentCard } from './AppointmentsPage';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { appointmentsCollectionRef } from '../../firebase/fire-app';

const AppointmentDetails = ({ appData, user, index }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {

        setIsModalVisible(false);

    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFormSubmit = (data, e) => {
        console.log(data)
        appData.updatedAt = new Date();
        appData.zoomLink = data.zoomLink;
        appData.appointmentTime = new Date(data.appointmentTime);

        appointmentsCollectionRef.doc(appData.id).set(appData, { merge: true }).then(() => {
            console.log("profile updated")
        })
    }
    return (
        <>
            <Button variant="dark" size="sm" onClick={showModal}>
                Details
            </Button>
            <Modal width={1000} title="Appointment Details" visible={isModalVisible} onCancel={handleCancel}>
                <AppointmentCard data={appData} user={user} index={index} />

                <div className="p-4">
                    <form onSubmit={handleSubmit(onFormSubmit)} >

                        <div className="row">
                            <InputWrapper col="6" label="Zoom Link">
                                <input name="zoomLink" {...register("zoomLink")} type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="6" label="Time">
                                <input name="appointmentTime" {...register("appointmentTime")} type="datetime-local"  className="form-control" />
                            </InputWrapper>
                            <div className="col-12 mt-4 text-center">
                                <button type="submit" className="btn btn-dark btn-sm">UPDATE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default AppointmentDetails;


const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{label} </span>
            </div>
            {children}
        </div>
    );
};