import { useState } from "react";
import { messagesCollectionRef } from "../../firebase/fire-app";
import { customDateFormat } from "../../utilities";
import "./message.scss";
import emailjs from "@emailjs/browser";

emailjs.init("G-4jKP2cBtVGSifKg");
const MessageComponent = ({ data }) => {
    const deleteMessage = (id) => {
        messagesCollectionRef.doc(id).delete();
    };
    const [replyMessage, setReplyMessage] = useState(null);

    const mailData = {
        subject: data.subject || "",
        mailBody: `${replyMessage}`,
        name: data.name,
        email: data.email,
    };
    const onMessageSend = () => {
        // emailjs
        // .send("service_cqswsdo", "template_inyi2k8", mailData)
        // .then(() => {
        //     console.log("done");
        // });

        emailjs
            .send("service_cqswsdo", "template_inyi2k8", mailData)
            .then(() => {
                messagesCollectionRef.doc(data.id).update({
                    replied: true,
                    replyMessage: replyMessage,
                });
            });
    };

    return (
        <div className="col-md-6 mb-4">
            <div className="outline-card p-4">
                <p>
                    <span className="text-secondary">Name: </span>{" "}
                    <b> {data.name}</b>{" "}
                </p>
                <p className="mb-1">
                    <span className="text-secondary">Email: </span>{" "}
                    <b> {data.email}</b>{" "}
                </p>
                <p className="mb-1">
                    <span className="text-secondary">Phone: </span>{" "}
                    <b> {data.phone}</b>{" "}
                </p>
                <p className="mb-2">
                    <span className="text-secondary">Sent at: </span>{" "}
                    <b> {customDateFormat(data.createdAt)}</b>{" "}
                </p>

                <p className="mb-1">
                    Subject: <b>{data.subject}</b>{" "}
                </p>
                <p>
                    {" "}
                    <b>Message: </b> {data.message}{" "}
                </p>
                {/* <div className="text-right">
                    <button
                        onClick={() => deleteMessage(data.id)}
                        className="btn btn-danger btn-sm mt-3"
                    >
                        Delete
                    </button>
                </div> */}

                <div className="row mt-2">
                    {data.replied ? (
                        <div>
                                                        <p className="text-italic font-bold">Our response:</p>

                            <div className="px-4">
                            <p className="text-italic">{data.replyMessage}</p>

                            </div>
                        </div>
                    ) : (
                        <div className="col-12">
                            <textarea
                                onChange={(e) =>
                                    setReplyMessage(e.target.value)
                                }
                                name=""
                                id=""
                                rows="3"
                                className="form-control"
                            ></textarea>
                            <div className="text-right mt-1">
                                {replyMessage ? (
                                    <button
                                        onClick={onMessageSend}
                                        className="btn btn-primary btn-sm"
                                    >
                                        Reply!
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="btn btn-primary btn-sm"
                                    >
                                        Reply!
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MessageComponent;
