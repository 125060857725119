import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import slugify from 'slugify';
import { useHistory } from 'react-router-dom';
import { useBlogs } from '../../Hooks/useBlogs';
import { newsCollectionRef, partnersCollectionRef } from '../../firebase/fire-app';

const AddPartner = () => {
    const { news } = useBlogs();
    const [similarBlog, setSimilarBlog] = useState(null);
    const [country, setCountry] = useState("Bangladesh");

    const changeCountry = (data) => {
        setCountry(data)
    }

    const { register, handleSubmit, watch, errors } = useForm();
    const blogObject = {
        name: "",
        slug: "",
        logo: "",
        country: "",
        descriptionOne: "",
        descriptionTwo: "",
        createdAt: new Date(),
    }
    const [blogSlug, setBlogSlug] = useState(null);
    const [blogTitle, setBlogTitle] = useState(null);
    const [logo, setLogo] = useState(null);

    const history = useHistory();
    const onTitleChange = (e) => {
        setBlogTitle(e.target.value)
    }
    const onSlugChange = (e) => {
        setBlogSlug(e.target.value)
    }
    const onLogoChange = (e) => {
        setLogo(e.target.value);
    }
    const onSubmit = (data, e) => {
        data.slug = blogSlug;
        data.name = blogTitle;
        data.logo = logo;
        data.country = country;

        const updatedBlog = { ...blogObject, ...data }
        partnersCollectionRef.add(updatedBlog).then((docRef) => {
            history.push("/partners/edit/" + docRef.id)
        })
        // blogsCollectionRef.doc(data.slug).set(updatedBlog)
    }
    return (
        <>
            <h3 className="text-center mt-4">Add New Partner</h3>
            {/* <h5 className="text-center">{similarBlog ? <span className="text-danger">Similar news url exists</span> : <span className="text-primary">Great! It's a unique News!</span>}</h5> */}
            {/* <button onClick={() => console.table(blogs)} className={`btn btn-primary ${similarBlog && "disabled"}`} >Log</button> */}
            {/* <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <InputWrapper col="6" label="Title">
                                    <input onChange={onTitleChange}  type="text" name="title" className="form-control"  ref={register()} />
                                </InputWrapper>
                                <InputWrapper col="6" label="URL Slug">
                                    <input type="text" name="slug" defaultValue={blogSlug} className="form-control" ref={register()} />
                                </InputWrapper>
                                <div className="col-12 text-center">
                                    {similarBlog ? <button disabled className="btn btn-secondary" type="submit" >Sorry!</button> : <button className="btn btn-primary" type="submit" >Next!</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div> */}
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <InputWrapper col="6" label="Name">
                                    <input onChange={onTitleChange} type="text" name="name" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="6" label="URL Slug">
                                    <input onChange={onSlugChange} type="text" name="slug" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="10" label="Logo">
                                    <input onChange={onLogoChange} type="text" name="logo" className="form-control" />
                                </InputWrapper>
                                <div className="col-md-4">
                                    <div className="btn-group mb-4">
                                        <button type="button" onClick={() => changeCountry('Sri-Lanka')} className={`btn btn-${country === "Sri-Lanka" ? "primary" : "secondary"}`}>Sri-Lanka</button>
                                        <button type="button" onClick={() => changeCountry('Bangladesh')} className={`btn btn-${country === "Bangladesh" ? "primary" : "secondary"}`}>Bangladesh</button>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    {similarBlog ? <button disabled className="btn btn-secondary" type="submit" >Sorry!</button> : <button className="btn btn-primary" type="submit" >Next!</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default AddPartner;

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{label} </span>
            </div>
            {children}
        </div>
    );
};

