import { userMetadataCollectionRef } from "../../firebase/fire-app";
import "./user-meta.scss";
import emailjs from '@emailjs/browser';
import { send } from "emailjs-com"
import UserDetailsPopup from "./UserDetailsPopup";


const UserMetaCard = ({ data }) => {
    const onApproveUser = () => {
        userMetadataCollectionRef.doc(data.id).update({
            approved: true
        }).then(()=>{
            const userData = {
                name: data.fullName,
                email: data.userEmail
            }
            send('service_cqswsdo', 'template_inyi2k8', userData, 'G-4jKP2cBtVGSifKg').then((res) => {
                alert("The user has been approved.");
            })
        })
    }
    const onDeclineUser = () => {
        userMetadataCollectionRef.doc(data.id).update({
            approved: false
        })
    }
    const notifyUser = () => {
        alert('request started')
        const userData = {
            name: data.fullName,
            email: data.userEmail
        }
        send('service_cqswsdo', 'template_lsys42e', userData, 'G-4jKP2cBtVGSifKg').then((res) => {
            alert("Thank you! We will reach you soon! ");
        })
        // emailjs.sendForm('service_cqswsdo', 'template_lsys42e', userData, 'G-4jKP2cBtVGSifKg')
        //     .then((result) => {
        //         alert(result.text);
        //     }, (error) => {
        //         alert(error.text);
        //     });
    }
    return (
        <>
            <div className="row user-meta-card mb-4 d-flex align-items-center">
                <div className="col-md-3">
                    <h6 className="font-bold">{data.fullName}</h6>
                    <p className="text-secondary font-14">{data.jobTitle}, {data.organization}</p>
                    <p className="text-secondary font-14">{data.country}</p>
                </div>
                <div className="col-md-3">
                    <p className="font-14">{data.userPhone}</p>
                    <p className="font-14">{data.userEmail}</p>
                </div>
                <div className="col-md-3">
                    <h6 className="font-14">Ref: {data.refName}</h6>
                    <p className="text-secondary font-14">{data.refJobTitle}, {data.refOrganization}</p>
                    <p className="font-14">{data.refEmail}</p>
                </div>
                <div className="col-md-3">
                    {data.approved ?
                        <button disabled className="btn btn-sm btn-primary mr-3">APPROVE</button>
                        :
                        <button onClick={onApproveUser} className="btn btn-sm btn-primary mr-3">APPROVE</button>}
                    {
                        !data.approved ?
                            <button disabled className="btn btn-sm btn-danger">DECLINE</button>

                            :
                            <button onClick={onDeclineUser} className="btn btn-sm mr-3 btn-danger">DECLINE</button>

                    }
                    {data && <UserDetailsPopup data={data}/>}
                    {/* <button onClick={notifyUser} className="btn btn-sm mr-3 btn-danger">REQUEST INFO</button> */}
                </div>
            </div>
        </>
    );
};

export default UserMetaCard;