import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import fireApp, { eventsCollectionRef, writersCollectionRef } from '../../firebase/fire-app';


const PageAddNewEvent = () => {
    const writerDoc = eventsCollectionRef.doc();
    const [writerId, setWriterId] = useState(writerDoc.id);
    const { register, handleSubmit, watch, errors } = useForm();
    const [userImg, setUserImg] = useState(null)
    const history = useHistory();
    const onWriterAdded = (data, e) => {
    
        data.img = userImg? userImg: null;
        console.log(data);
        eventsCollectionRef.doc(writerId).set(data).then(()=>{
            e.target.reset();
            setWriterId(null);
            setUserImg(null);
            history.push('/events')
        })
    }

    const onUserImgUploaded = (e) => {
        let storageRef = fireApp.storage().ref();
        let blogStorage = storageRef.child(`events/${writerId}`);
        const imgFile = e.target.files[0];
        blogStorage
            .put(imgFile).then((snapshot) => {
                snapshot.ref.getDownloadURL().then(res => {
                    setUserImg(res);
                })
            })
    }
    return (
        <>
            <section className="bg-white">
                <div className="container-fluid">
                    <form className="p-5" onSubmit={handleSubmit(onWriterAdded)}>

                        <Row>
                            <InputWrapper col={7} label="Event Name" >
                                <input {...register("name")} type="text" name="name" className="form-control" placeholder="event name..." />
                            </InputWrapper>
                            <InputWrapper col={5} label="Date & Time">
                                <input {...register("dateTime")} type="datetime-local" name="dateTime" className="form-control" placeholder="" />
                            </InputWrapper>
                            <InputWrapper col={12} label="Brief Description">
                                <input {...register("brief")} type="text" name="brief" className="form-control" placeholder="short description here..." />
                            </InputWrapper>

                            <InputWrapper col={5} label="Cover Image">
                                <input type="file" className="form-control" onChange={onUserImgUploaded} />
                            </InputWrapper>
                            <div className="col-md-7"></div>
                            <div className="col-md-2">
                                {userImg && <img src={userImg} alt="" className="img-fluid" />}
                            </div>
                            <div className="col-12 mt-4"><h5> Event Information </h5></div>
                            <InputWrapper col={5} label="Location">
                                <input {...register("location")} type="text" name="location" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col={8} label="Registration Link">
                                <input {...register("registrationLink")} type="text" name="registrationLink" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col={6} label="FB Event Link">
                                <input {...register("fbEvent")} type="text" name="fbEvent" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col={6} label="Emergency Contact: ">
                                <input {...register("contact")} type="text" name="contact" className="form-control" />
                            </InputWrapper>

                            <div className="col-12 text-center">
                                <Button type="submit">Add New Event</Button>
                            </div>
                        </Row>

                    </form>
                </div>
            </section>
        </>
    );
};

export default PageAddNewEvent;



const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};