import ResourceCard from "./ResourceCard";
import fireApp, { resourcesCollectionRef, writersCollectionRef } from '../../firebase/fire-app';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import "./resources.scss";
import ResourceNavbar from "./ResourceNavbar";
import { useState } from "react";
import { act } from "react-dom/test-utils";


const PageAllResources = () => {
    const [active, setActive] = useState('ALL');

    const [allResources, loading, error] = useCollectionData(resourcesCollectionRef.orderBy('createdAt', 'desc'), { idField: 'id' });


    return (
        <>
            <main className="resources">
                {/* <ResourceNavbar pubTitle="IEC Materials" act="1" /> */}

                <section className="bg-light">
                    <div className="container mb-3">
                        <ResourceNavbar setActive={setActive} />
                    </div>
                    <div className="container">
                        <div className="row">
                            {allResources && allResources.length > 0 && allResources.map(item => <ResourceCard active={active} data={item} key={item.id} />)}

                        </div>

                        {/* {events && events.map(item => <EventCard data={item} key={item.id} />)} */}
                    </div>
                </section>
            </main>
        </>
    );
};

export default PageAllResources;

