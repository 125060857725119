import { Layout, Menu } from "antd";
import { UploadOutlined, InsertRowLeftOutlined, BookOutlined, FormOutlined, CalendarOutlined, RadarChartOutlined, EyeInvisibleOutlined, HomeOutlined, FileOutlined, TeamOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import './AntdMenu.scss';
import { useAuth } from "../../Hooks/useAuth";
import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";

const { Sider, } = Layout;
const { SubMenu } = Menu;


const AntdMenu = ({ collapsed, setCollapsed }) => {
    const { hasAccess } = useAuth()
    const showMenu = hasAccess;

    const onLogOut = () => {
        firebase.auth().signOut().then(res => {
            sessionStorage.removeItem("token");
            window.location.href = '/'
        }).catch(err => {
            alert(err);
        })
    }
    return (
        <>
            <Sider title="World of Peace"
                style={{
                    height: '100vh',
                    position: 'fixed',
                    zIndex: '20'
                }}
                breakpoint="sm"
                collapsedWidth="0">
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu ? '1' : '5']}>
                    <h5 className="text-white text-center mt-4 mb-4">World Peace </h5>
                    {showMenu &&
                        <Menu.Item key="1" icon={<HomeOutlined />}>
                            <Link className=" mt-3" to="/">Home</Link>
                        </Menu.Item>}
                    {showMenu &&
                        <Menu.Item key="01" icon={<HomeOutlined />}>
                            <Link className=" mt-3" to="/messages">Messages</Link>
                        </Menu.Item>}
                    {showMenu && <Menu.Item key="6" icon={<InsertRowLeftOutlined />}>
                        <Link className="mt-2" to="/resources">All Resources</Link>
                    </Menu.Item>}
                    {showMenu && <Menu.Item key="7" icon={<FormOutlined />}>
                        <Link className="mt-2" to="/resources/add">Add New Resource</Link>
                    </Menu.Item>}
                    {showMenu && <Menu.Item key="8" icon={<RadarChartOutlined />}>
                        <Link className="mt-2" to="/events">All Events</Link>
                    </Menu.Item>}
                    {showMenu && <Menu.Item key="9" icon={<FormOutlined />}>
                        <Link className="mt-2" to="/events/add">Add New Event</Link>
                    </Menu.Item>}
                    {/* gallery */}
                    {showMenu && <Menu.Item key="99" icon={<InsertRowLeftOutlined />}>
                        <Link className="mt-2" to="/gallery">Gallery</Link>
                    </Menu.Item>}

                    {/* partners */}
                    {showMenu && <Menu.Item key="10" icon={<RadarChartOutlined />}>
                        <Link className="mt-2" to="/partners">Partners</Link>
                    </Menu.Item>}
                    {showMenu && <Menu.Item key="11" icon={<FormOutlined />}>
                        <Link className="mt-2" to="/partners/add">Add Partner</Link>
                    </Menu.Item>}

                    {/* Programs */}
                    {showMenu && <Menu.Item key="110" icon={<RadarChartOutlined />}>
                        <Link className="mt-2" to="/programs">Programs</Link>
                    </Menu.Item>}
                    {showMenu && <Menu.Item key="111" icon={<FormOutlined />}>
                        <Link className="mt-2" to="/programs/add">Add Program</Link>
                    </Menu.Item>}

                    {showMenu && <Menu.Item key="12" icon={<FormOutlined />}>
                        <Link className="mt-2" to="/admins">Admins</Link>
                    </Menu.Item>}

                    {/* {showMenu && <Menu.Item key="10" icon={<TeamOutlined />}>
                        <Link className="mt-2" to="/writers">All Writers</Link>
                    </Menu.Item>}
                    {showMenu && <Menu.Item key="11" icon={<UserAddOutlined />}>
                        <Link className="mt-2" to="/writers/add">Add Writer</Link>
                    </Menu.Item>} */}

                    {showMenu ?
                        <div className="text-center">
                            <button onClick={onLogOut} className="btn btn-primary btn-sm  mt-3">Log out</button>
                        </div> :
                        <Menu.Item key="15" icon={<EyeInvisibleOutlined />}>
                            <Link className=" mt-3" to="/login">Login</Link>
                        </Menu.Item>}
                </Menu>
            </Sider>
            <div className="d-none d-md-block">
                <Sider title="World Peace"
                    style={{
                        height: '100vh',

                    }}
                    breakpoint="sm"
                    collapsedWidth="0">
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu ? '1' : '5']}>
                        <h4 className="text-white text-center mt-3">World Peace </h4>
                        <hr />
                        {showMenu &&
                            <Menu.Item key="1" icon={<HomeOutlined />}>
                                <Link className=" mt-3" to="/">Home</Link>
                            </Menu.Item>}
                        {showMenu && <Menu.Item key="6" icon={<InsertRowLeftOutlined />}>
                            <Link className="mt-2" to="/resources">All Resources</Link>
                        </Menu.Item>}
                        {showMenu && <Menu.Item key="7" icon={<FormOutlined />}>
                            <Link className="mt-2" to="/resources/add">Add New Resource</Link>
                        </Menu.Item>}
                        {showMenu && <Menu.Item key="8" icon={<RadarChartOutlined />}>
                            <Link className="mt-2" to="/events">All Events</Link>
                        </Menu.Item>}
                        {showMenu && <Menu.Item key="9" icon={<FormOutlined />}>
                            <Link className="mt-2" to="/events/add">Add New Event</Link>
                        </Menu.Item>}
                        {showMenu && <Menu.Item key="10" icon={<TeamOutlined />}>
                            <Link className="mt-2" to="/writers">All Writers</Link>
                        </Menu.Item>}
                        {showMenu && <Menu.Item key="11" icon={<UserAddOutlined />}>
                            <Link className="mt-2" to="/writers/add">Add Writer</Link>
                        </Menu.Item>}
                    </Menu>
                </Sider>
            </div>
        </>
    );
};

export default AntdMenu;