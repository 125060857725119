import { Modal, Button } from "react-bootstrap";
import YoutubeResponsiveEmbed from "./YoutubeResponsiveEmbed";

const YouTubePlayerModal = ({data, showModal, onHide}) => {
    return (
        <>
            <Modal
                show={showModal}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {data.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <YoutubeResponsiveEmbed youtubeId={data.videoId}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default YouTubePlayerModal;