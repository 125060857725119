import { useState } from "react";
import ImagesPage from "./ImagesPage";
import VideosPage from "./VideosPage";

const GalleryPage = () => {
    const [images, setImages] = useState(true);
    const [videos, setVideos] = useState(false);
    const onImageActive =()=>{
        setImages(true);
        setVideos(false)
    }
    const onVideosActive =()=>{
        setImages(false);
        setVideos(true)
    }
    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="btn-group">
                                <button onClick={onImageActive} className={`btn btn-${images? 'primary' : 'secondary'}`}>Images</button>
                                <button onClick={onVideosActive} className={`btn btn-${videos? 'primary' : 'secondary'}`}>Videos</button>
                            </div>
                        </div>
                    </div>
                    {images && <ImagesPage/>}
                    {videos && <VideosPage/>}
                </div>
            </section>
        </>
    );
};

export default GalleryPage;