import { Link } from "react-router-dom";
import { partnersCollectionRef } from "../../firebase/fire-app";
import "./style.scss";

const PartnerCard = ({ data }) => {
//     const siteUrl = `https://theworldofpeace.vercel.app`;
//     const cardLogo = siteUrl + data.logo;
    // data.logo = cardLogo;
    const submitPartner = () => {
        // const partnerInfo = { ...data, logo: cardLogo };
        // partnerInfo.createdAt = new Date();
        // partnerInfo.country = "Bangladesh"
        // partnersCollectionRef.add(partnerInfo).then(res =>{
        //     console.log("added");
        // })

    }
    return (
        <>
            <div className="col-md-6 mb-4">

                {/* <Link href={`/partner/${data.slug}`} passHref> */}
                <div className="partner-card isClickable px-5 py-5">
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <h2 className="font-bold font-26">{data.name}</h2>
                        {data.logo && <img width={100} height={60} objectFit="contain" alt="Landscape picture" src={data.logo} />
                        }
                    </div>
                    <p className="font-18 mb-3">
                        {data.description}
                    </p>
                    {/* <Link href={"/partner/" + data.slug} passHref>
                            <a href="#" className="btn btn-outline-primary">View Details</a>
                        </Link> */}
                    <Link to={"/programs/edit/"+data.id} className="btn btn-primary">Edit Info</Link>
                    {/* <button onClick={submitPartner} className="btn btn-primary">Add Now!</button> */}
                </div>
                {/* </Link> */}
            </div>
        </>
    )
}

export default PartnerCard;