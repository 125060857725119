import { Tag } from "antd";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { blogsCollectionRef } from "../../firebase/fire-app";
import { customDateFormat } from "../../utilities";
import DeleteBlog from "./DeleteBlog";

const BlogContentCard = ({blog}) => {
    return (
        <>
             <>
        {blog ?<div className="col-md-12 mb-4">
            <div className="card p-4 ">
                <div className="row">
                    <div className="col-md-4 border-right">
                    <h4>{blog.title} {blog.featured && <Tag color="#f50"> Featured Blog</Tag> } </h4>
                    <p className="mt-4 mb-1"><i>Created at: {customDateFormat(blog.createdAt)}</i></p>
                    <p className=" mb-1"><i>Last updated : {customDateFormat(blog.lastUpdated)}</i></p>

                    <div className="mt-3">
                        {/* <Link to={`/blog/${blog.id}`} className="btn btn-primary mr-3 btn-sm">View</Link> */}
                        <Link to={`/blog/edit/${blog.id}`} className='btn btn-warning mr-3 btn-sm'>Edit</Link>
                        {/* <button className="btn btn-danger btn-sm">Delete</button> */}
                        <DeleteBlog blog={blog} />
                    </div>
                    </div>
                    <div className="col-md-4">
                    <p><i>Category: <b> {blog.category}</b> </i> </p>
                    <p className=""><span className="font-weight-bold">Tags: 
                    {blog.tags && blog.tags.length > 0 && blog.tags.map(item => <Tag color='blue'>#{item} </Tag>) }
                     </span> </p>
                     <p className="mb-1"> <i>Status:  <span className="font-weight-bold">{blog.status} </span></i> </p>
                     <p> <i>Total views:  <span className="font-weight-bold">{blog.totalViews? blog.totalViews : 0} </span></i> </p>

                    </div>
                    <div className="col-md-3">
                        <img src={blog.featuredImage} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div> : <Spinner animation='grow' variant='primary'/> }
        </>
        </>
    );
};

export default BlogContentCard;