import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import fireApp, { resourcesCollectionRef } from '../../firebase/fire-app';



const PageAddResources = () => {
    const writerDoc = resourcesCollectionRef.doc();
    const [writerId, setWriterId] = useState(writerDoc.id);
    const { register, handleSubmit, watch, errors } = useForm();
    const [userImg, setUserImg] = useState(null)
    const history = useHistory();
    const onWriterAdded = (data, e) => {
        data.createdAt = new Date();
        data.img = userImg ? userImg : null;
        if(!data.downloadLink){
            data.downloadLink = userImg;
        }
        console.log(data);
        resourcesCollectionRef.doc(writerId).set(data).then(() => {
            e.target.reset();
            setWriterId(null);
            setUserImg(null);
            history.push('/resources')
        })
    }

    const onUserImgUploaded = (e) => {
        let storageRef = fireApp.storage().ref();
        let blogStorage = storageRef.child(`resources/${writerId}`);
        const imgFile = e.target.files[0];
        blogStorage
            .put(imgFile).then((snapshot) => {
                snapshot.ref.getDownloadURL().then(res => {
                    setUserImg(res);
                })
            })
    }
    return (
        <>
            <section className="bg-white">
                <div className="container-fluid">
                    <form className="p-5" onSubmit={handleSubmit(onWriterAdded)}>

                        <Row>
                            <InputWrapper col={7} label="Resource Name" >
                                <input {...register("title")} type="text" name="title" className="form-control" placeholder="resource title..." />
                            </InputWrapper>
                            <InputWrapper col={5} label="Cover Image">
                                <input type="file" className="form-control" onChange={onUserImgUploaded} />
                            </InputWrapper>
                            <InputWrapper col={12} label="Download Link">
                                <input {...register("downloadLink")} type="text" name="downloadLink" className="form-control" placeholder="download link..." />
                            </InputWrapper>
                            <InputWrapper col={6} label="Category" >
                                <select {...register("category")} type="text" name="category" className="form-control" >
                                    <option value="">Select resource category</option>
                                    <option value="REPORTS">REPORTS</option>
                                    <option value="MODULES">MODULES</option>
                                    <option value="IEC_MATERIALS">IEC_MATERIALS</option>
                                    <option value="NEWSLETTER">NEWSLETTER</option>
                                </select>
                            </InputWrapper>
                            <InputWrapper col={6} label="Country" >
                                <select {...register("country")} type="text" name="country" className="form-control" >
                                    <option value="">Select country</option>
                                    <option value="BGD">BGD</option>
                                    <option value="LKA">LKA</option>
                                </select>
                            </InputWrapper>

                            <div className="col-md-7"></div>
                            <div className="col-md-2">
                                {userImg && <img src={userImg} alt="" className="img-fluid" />}
                            </div>

                            <div className="col-12 text-center">
                                <Button type="submit">Add</Button>
                            </div>
                        </Row>

                    </form>
                </div>
            </section>
        </>
    );
};

export default PageAddResources;


const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};