import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { programsCollectionRef } from "../../firebase/fire-app";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RichTextEditor from "./RichTextEditor";
import { useHistory } from 'react-router-dom';

const EditProgramInfo = () => {
    const { programID } = useParams();
    const [loading, setLoading] = useState(true);
    const [partnerData, setPartnerData] = useState(null);
    const history = useHistory();


    const [descriptionOne, setDescriptionOne] = useState('');
    const [descriptionTwo, setDescriptionTwo] = useState('');


    useEffect(() => {
        if (programID) {
            programsCollectionRef.doc(programID).get().then((doc) => {
                setPartnerData(doc.data());
                setLoading(false)
            })
        }
    }, [programID])
    useEffect(() => {
        if (partnerData) {
            setDescriptionOne(partnerData.descriptionOne);
            setDescriptionTwo(partnerData.descriptionTwo);
        }
    }, [partnerData])



    const updateProgramData = () => {
        const updatedInfo = {
            country: partnerData.country,
            name: partnerData.name,
            objective: partnerData.objective,
            slug: partnerData.slug,
            descriptionOne: descriptionOne,
            descriptionTwo: descriptionTwo,
        }
        programsCollectionRef.doc(programID).set(updatedInfo).then(() => {
            alert('updated!')
        })
    }
    const deleteProgram = () => {
        programsCollectionRef.doc(programID).delete().then(() => {
            history.push("/programs")
        })
    }
    return (
        <>
            {/* <h4 className="font-bold">{programID}</h4> */}
            {partnerData && <div className="container-fluid">
                <div className="row mb-4">

                    <div className="col-md-10">
                        <h4 className="font-bold">{partnerData.name} </h4>
                        <h3>Country: {partnerData.country}</h3>
                        <p className="mt-4"><b>Objective:</b> {partnerData.objective} </p>
                    </div>
                    <div className="col-md-2">
                        <button onClick={deleteProgram} className="btn btn-danger">
                            Delete This Program
                        </button>
                    </div>
                </div>
                <div className="row">
                    {/* <InputWrapper col="4" label="Name">
                        <input type="text" name="name" className="form-control" defaultValue={partnerData.name} />
                    </InputWrapper>
                    <InputWrapper col="3" label="Country">
                        <input type="text" name="country" className="form-control" defaultValue={partnerData.country} />
                    </InputWrapper> */}
                    <InputWrapper col="12" label="Objective">
                        <input type="text" name="objective" className="form-control" defaultValue={partnerData.objective} />
                    </InputWrapper>
                    {/* <InputWrapper col="2" label="Slug">
                        <input type="text" name="slug" className="form-control" defaultValue={partnerData.slug} />
                    </InputWrapper> */}
                    {/*             
                    <InputWrapper col="6" addClass='mt-4' label="Description">
                        <textarea defaultValue={partnerData.description} className="form-control" name="description" id="" cols="30" rows="8"></textarea>
                    </InputWrapper>
                    <InputWrapper col="6" addClass='mt-4' label="Initiatives">
                        <textarea defaultValue={partnerData.initiatives} className="form-control" name="initiatives" id="" cols="30" rows="8"></textarea>
                    </InputWrapper>
                    <InputWrapper col="6" label="Achievements">
                        <textarea defaultValue={partnerData.achievements} className="form-control" name="achievements" id="" cols="30" rows="8"></textarea>
                    </InputWrapper> */}
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <RichTextEditor title='Info Part One' defaultText={partnerData.descriptionOne} returnText={setDescriptionOne} />
                    </div>
                    <div className="col-md-6">
                        <RichTextEditor title='Info Part Two' defaultText={partnerData.descriptionTwo} returnText={setDescriptionTwo} />
                    </div>
                    <div className="col-12 mt-4 text-center">
                        <button onClick={updateProgramData} className="btn btn-primary">Update Program Info</button>
                    </div>
                </div>


            </div>}
            {/* <p>{briefData}</p> */}
        </>
    );
};

export default EditProgramInfo;

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{label} </span>
            </div>
            {children}
        </div>
    );
};

