import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { galleryCollectionRef } from "../../firebase/fire-app";
import YoutubeResponsiveEmbed from "./YoutubeResponsiveEmbed";



const AddNewVideoPopup = () => {
    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [videoID, setVideoID] = useState();

    const onHideModal = () => {
        setShowModal(!showModal);
    }
    const onFormSubmit = (data, e) => {
        data.sl = Number(data.sl)
        data.videoId = videoID;
        galleryCollectionRef.doc('videos').collection('videos').add(data).then(() => {
            e.target.reset();
            setShowModal(false);
        })
    }

    const onVideoIDChange = (e) => {
        const vidID = e.target.value;
        setVideoID(vidID)
    }

    return (
        <>
            <button onClick={() => setShowModal(true)} className=" btn btn-primary mb-4">ADD NEW VIDEO</button>

            <Modal
                show={showModal}
                onHide={onHideModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-little-vcenter'>
                        Add video
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <form onSubmit={handleSubmit(onFormSubmit)} >
                                <div className="mb-3">
                                    <InputWrapperComponent required htmlFor={"title"} label="Title">
                                        <input {...register("title", { required: true })} type="text" className="form-control" name="title" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"videoId"} label="Video ID:">
                                        <input required onChange={onVideoIDChange} type="text" className="form-control" name="videoId" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"tag"} label="Tag line: ">
                                        <input {...register("tag", { required: true })} type="text" className="form-control" name="tag" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"sl"} label="Serial:">
                                        <input {...register("sl", { required: true })} type="sl" className="form-control" name="sl" />
                                    </InputWrapperComponent>

                                    <div className="row">
                                        <div className="col-md-6 offset-md-3">
                                            <button type="submit" className="btn btn-primary mb-4 mr-3">Add Video</button>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 mt-3">
                            <YoutubeResponsiveEmbed youtubeId={videoID}/> 
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddNewVideoPopup;




const InputWrapperComponent = ({ children, label, htmlFor, required }) => {
    return (
        <div className="mb-3 row">
            <label htmlFor={htmlFor} className="col-sm-3 col-form-label">{label} {required && <span className="text-danger font-bold">*</span>}</label>
            <div className="col-sm-9">
                {children}
            </div>
        </div>
    )
}