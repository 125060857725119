import { BsArrowRight } from "react-icons/bs";
import fireApp, { resourcesCollectionRef } from '../../firebase/fire-app';



const ResourceCard = ({ data, bg, active }) => {

    return (
        <>
            {active === 'ALL' ? <CardComp data={data} bg={bg} /> : 
            active === data.category ? <CardComp data={data} bg={bg} /> : null}
        </>
    )
}

const CardComp = ({ data, bg }) => {
    const onDeleteResource = () => {
        resourcesCollectionRef.doc(data.id).delete().then(() => {
            alert('Resource item deleted!')
        })
    }
    return (
        <div className="col-md-4 mb-4">
            <div className={`resource-card bg-${bg ? bg : "primary"} `}>
                <div className="info p-4">
                    <p>{data.category}</p>
                    <hr />
                    <div className="title mt-3 ">
                        <h2 className="font-22 text-white font-bold underline-animation">
                            {data.title}
                        </h2>
                    </div>
                </div>
                <div className="img-container img-wrapper">
                    <img className="img-fluid" src={data.img} width={680} height={410} objectFit="cover" alt={data.title} />
                </div>

                <div className="py-2 d-flex px-4 pb-3 justify-content-between">
                    <a href={data.downloadLink} className="btn btn-link text-white underline-animation" target="_blank" rel="noopener noreferrer">Download Here</a>
                    <button onClick={onDeleteResource} className="btn-sm btn btn-danger">Delete</button>


                </div>
                <div className="bg-white text-right px-3">
                </div>
            </div>
        </div>
    )
}

export default ResourceCard;