import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import fireApp from "../../firebase/fire-app";

const PasswordResetPopup = () => {
    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();



    const onHideModal = () => {
        setShowModal(!showModal);
    }

    const onFormSubmit = (data, e) => {
        console.log(data);

        const email = data.userEmail;

        fireApp.auth().sendPasswordResetEmail(email).then(() => {
            e.target.reset();
            setShowModal(false);
            alert('Password reset email sent to your email address.');
        })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage);
            })

    }
    return (
        <>
             <Modal
                show={showModal}
                onHide={onHideModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-little-vcenter'>
                        Reset Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7">
                            <form onSubmit={handleSubmit(onFormSubmit)} >
                                <div className="mb-3 row">
                                    <label htmlFor="inputEmail" className="col-sm-3 col-form-label">Email</label>
                                    <div className="col-sm-9">
                                        <input type="email" placeholder="your email" {...register("userEmail", { required: true })} className="form-control" id="inputEmail" />

                                        <button type="submit" className="btn btn-primary mt-3 me-3">Reset</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <button onClick={() => setShowModal(true)} className="btn btn-sm btn-outline-primary">Reset Password</button>
        </>
    );
};

export default PasswordResetPopup;