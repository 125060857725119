import { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import fireApp, { galleryCollectionRef } from "../../firebase/fire-app";
import imageCompression from 'browser-image-compression';
import AddImageSectionPopup from "./AddImageSectionPopup";



const storageRef = fireApp.storage().ref().child("gallery-img");

const ImagesPage = () => {
    const [imageModules, load, error] = useCollectionData(galleryCollectionRef.doc('images').collection('images').orderBy('sl', 'asc'), { idField: 'id' });
    const [uploadedImg, setUploadedImg] = useState(null)
    const [editMode, setEditMode] = useState(false);

    const onImageModuleClick = (item) => {
        setActiveModule(item);
        // alert(JSON.stringify(item))
    }

    const [activeModule, setActiveModule] = useState(null);

    useEffect(()=>{
        if(!load && !error && imageModules.length > 0){
            setActiveModule(imageModules[0])
        }
    },[imageModules])
    const onImageUpload = async (e) => {
        const eventTarget = e.target.name;
        const file = e.target.files[0];

        const options = {
            maxSizeMB: .5,
            maxWidthOrHeight: 1080,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(file, options);
            // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            const imageFile = {
                fileRes: compressedFile,
                fileName: file.name
            };
            if (imageFile.fileRes) {
                const fileRef = storageRef.child(activeModule.id + `/` + file.name);
                fileRef.put(imageFile.fileRes).then((snapShot) => {
                    snapShot.ref.getDownloadURL().then(res => {
                        setUploadedImg(res)
                    })
                })
            }
        } catch (error) {
            console.log(error);
        }

    }
    console.log('h')

    const onAddImage = () => {
        console.log('hello')
        const previousImages = activeModule.images;
        console.log('on add');
        console.log('previous: ' + previousImages);
        const updatedImages = [...previousImages, uploadedImg];
        const updatedModule = { ...activeModule, images: updatedImages };
        console.log("updated module" + updatedModule);
        setActiveModule({ ...updatedModule });
        setUploadedImg(null)
    }
    const onImageRemove = (img) => {
        const previousImages = activeModule.images;
        const updatedImages = previousImages.filter(x => x !== img);
        const updatedModule = { ...activeModule, images: updatedImages };
        setActiveModule({ ...updatedModule });

    }
    const updateModule = () => {
        galleryCollectionRef.doc('images').collection('images').doc(activeModule.id).update({
            ...activeModule
        }).then(() => {
            setEditMode(false);
            alert('Uploaded the images!');
        })
    }

    const onDeleteSection =()=>{
        galleryCollectionRef.doc('images').collection('images').doc(activeModule.id).delete().then(()=>{
            setActiveModule(null);
        })
    }
    return (
        <>
            <div className="row mt-4">
                <div className="col-md-9 pb-5 pr-5">
                    {activeModule && <div >
                        <div className="d-flex mb-3">
                            <h4>{activeModule.sl}. {activeModule.title}</h4>
                            <button onClick={() => setEditMode(true)} className="btn-primary ml-4 btn-sm btn-primary btn">EDIT SECTION</button>
                            <button onClick={onDeleteSection} className="btn btn-danger btn-sm ml-4">DELETE SECTION</button>
                        </div>
                        <div className="row">
                            {activeModule.images.map(item => <ImageCard onImageRemove={onImageRemove} editMode={editMode} url={item} />)}

                            {editMode && <div className="col-md-4 d-flex align-items-center justify-content-center">
                                <div className="bg-light text-center border">
                                    {uploadedImg ? <img src={uploadedImg} alt="" className="img-fluid" /> : <img src="/img/upload.jpg" alt="" className="img-fluid" />}
                                    <input type="file" name="image" onChange={onImageUpload} className="mt-3" />

                                    {uploadedImg && <button onClick={onAddImage} className="btn mb-3 btn-sm btn-primary mt-3">ADD THIS IMAGE</button>}
                                </div>
                            </div>}
                            <div className="col-md-6 mt-5 offset-md-3">
                                <button onClick={updateModule} type="button" className="btn btn-block btn-primary">UPDATE THIS SECTION</button>
                            </div>
                        </div>
                    </div>}

                </div>
                <div className="col-md-3 pt-5">
                    {!load && !error && imageModules.length > 0 && imageModules.map(item => <button onClick={() => onImageModuleClick(item)} className="btn text-left btn-primary mb-2 btn-block">{item.sl}. {item.title}</button>)}
                    <AddImageSectionPopup />

                </div>
            </div>
        </>
    );
};

export default ImagesPage;


const ImageCard = ({ url, editMode, onImageRemove }) => {
    return (
        <>
            <div className="col-md-4 mb-4">
                <img src={url} alt="" className="img-fluid" />
                {editMode && <div className="text-center mt-2">
                    <button onClick={() => onImageRemove(url)} className="btn btn-sm btn-secondary">REMOVE</button>
                </div>}
            </div>
        </>
    )
}