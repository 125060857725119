import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import UserMetaCard from '../Components/UserMetadata/UserMetaCard';
import { userMetadataCollectionRef } from '../firebase/fire-app';

const TestPage = () => {
    const [allUsers, loading, error] = useCollectionData(userMetadataCollectionRef.orderBy('approved', 'asc').orderBy('createdAt', 'desc'), {idField: 'id'}
    );
    userMetadataCollectionRef.orderBy('approved', 'asc').orderBy('createdAt', 'desc').get();
    return (
        <>

            <h3 className="text-left font-bold">PVE Hub Users</h3>
            <section>
                <div className="container-fluid">
                    {
                        allUsers && allUsers.length>0 && allUsers.map(item => <UserMetaCard data={item}/>)
                    }
                </div>
            </section>
        </>
    );
};

export default TestPage;