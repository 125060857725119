import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { galleryCollectionRef } from "../../firebase/fire-app";
import AddNewVideoPopup from "./AddNewVideoPopup";
import YouTubePlayerModal from "./YouTubePlayerModal";
import YoutubeResponsiveEmbed from "./YoutubeResponsiveEmbed";

const VideosPage = () => {
    const [videos, loading, error] = useCollectionData(galleryCollectionRef.doc('videos').collection('videos').orderBy('sl', 'asc'), { idField: 'id' });

    return (
        <>
            <section className="bg-light py-5">
                <AddNewVideoPopup />

                <div className="container-fluid">
                    <div className="row">
                        {
                            !loading && !error && videos.length > 0 && videos.map(item => <YouTubeVideoCard data={item} key={item.id} />)
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default VideosPage;






const YouTubeVideoCard = ({ data }) => {
    const [showModal, setShowModal] = useState(false);

    const onHide = () => {
        setShowModal(false);
    }

    const onVideoCardClicked = () => {
        setShowModal(true)
    }
    const onDeleteVideo = (id) => {
        galleryCollectionRef.doc('videos').collection('videos').doc(id).delete(0).then(() => {
            alert('Deleted Video!')
        })
    }
    return (
        <>
            <div className="col-md-3 mb-4">

                <div className="video-card">
                    <YoutubeResponsiveEmbed youtubeId={data.videoId} />
                    <div className="content-brief bg-primary text-white">
                        <div onClick={() => onVideoCardClicked()} >
                            <span className="text-grey d-inline-block mb-2 mt-3">
                                {data.tag}
                            </span>
                            <p className="font-bold mb-4 font-2">
                                {data.title}
                            </p>
                        </div>
                        <button onClick={() => onDeleteVideo(data.id)} className="btn btn-sm btn-danger">DELETE VIDEO</button>

                    </div>
                </div>
                <YouTubePlayerModal showModal={showModal} onHide={onHide} data={data} />
            </div>
        </>
    )
}