import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import slugify from 'slugify';
import { useHistory } from 'react-router-dom';
import { useBlogs } from '../../Hooks/useBlogs';
import {   newsCollectionRef } from '../../firebase/fire-app';

const AddNewNews = () => {
    const { news } = useBlogs();
    const [similarBlog, setSimilarBlog] = useState(null);

    const { register, handleSubmit, watch, errors } = useForm();
    const blogObject = {
        title: "",
        slug: "",
        featuredImage: ``,
        content: ``,
        status: `Draft`,
        tags: [],
        category: ``,
        createdAt: new Date(),
        lastUpdated: ``,
        totalViews: 0
    }
    const [blogSlug, setBlogSlug] = useState(null);
    const [blogTitle, setBlogTitle] = useState(null)
    const history = useHistory();
    const onTitleChange = (e) => {
        setBlogTitle(e.target.value)
        const slu = slugify(e.target.value, {
            replacement: '-',
            remove: undefined,
            lower: true,
            strict: true,
            locale: 'vi'       // language code of the locale to use
        })
        if (news) {
            console.log("news are available");
            let sameBlog = null;
            sameBlog = news.find(item => item.slug === slu);
            setSimilarBlog(sameBlog)
            setBlogSlug(slu)
        }else{
            console.log(slu);
            setSimilarBlog(false)
            setBlogSlug(slu)
        }

    }
    const onSubmit = (data, e) => {
        data.slug = blogSlug;
        data.title = blogTitle;
        console.log(data);

        const updatedBlog = { ...blogObject, ...data }
        newsCollectionRef.add(updatedBlog).then((docRef)=>{
            history.push("/news/edit/" + docRef.id)
        })
        // blogsCollectionRef.doc(data.slug).set(updatedBlog)
    }
    return (
        <>
            <h3 className="text-center mt-4">Add New News</h3>
            <h5 className="text-center">{similarBlog ? <span className="text-danger">Similar news url exists</span> : <span className="text-primary">Great! It's a unique News!</span>}</h5>
            {/* <button onClick={() => console.table(blogs)} className={`btn btn-primary ${similarBlog && "disabled"}`} >Log</button> */}
            {/* <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <InputWrapper col="6" label="Title">
                                    <input onChange={onTitleChange}  type="text" name="title" className="form-control"  ref={register()} />
                                </InputWrapper>
                                <InputWrapper col="6" label="URL Slug">
                                    <input type="text" name="slug" defaultValue={blogSlug} className="form-control" ref={register()} />
                                </InputWrapper>
                                <div className="col-12 text-center">
                                    {similarBlog ? <button disabled className="btn btn-secondary" type="submit" >Sorry!</button> : <button className="btn btn-primary" type="submit" >Next!</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div> */}
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <InputWrapper col="6" label="Title">
                                    <input onChange={onTitleChange} type="text" name="title" className="form-control"  />
                                </InputWrapper>
                                <InputWrapper col="6" label="URL Slug">
                                    <input type="text" name="slug" defaultValue={blogSlug} className="form-control" {...register('slug')} />
                                </InputWrapper>
                                <div className="col-12 text-center">
                                    {similarBlog ? <button disabled className="btn btn-secondary" type="submit" >Sorry!</button> : <button className="btn btn-primary" type="submit" >Next!</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default AddNewNews;

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{label} </span>
            </div>
            {children}
        </div>
    );
};

