import { useCollectionData } from "react-firebase-hooks/firestore";
import { writersCollectionRef } from "../../firebase/fire-app";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";

const AllWriters = () => {
    const [writers, loading, error] = useCollectionData(writersCollectionRef, { idField: "id" });
    return (
        <>
            <h4 className="font-weight-bold mb-4">All Writers: </h4>
            <div className="container-fluid">
                <div className="row">
                    {writers && writers.map(item => <WriterCard writer={item} key={item.id} />)}
                </div>
            </div>
        </>
    );
};

export default AllWriters;

const WriterCard = ({ writer }) => {
    return (
        <>
            <div className="col-md-6 mb-3">
                <div className="card p-3">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={writer.img && writer.img} alt="" className="img-fluid" />
                            <Link to={"/writers/edit/" + writer.id} className="btn btn-danger btn-sm mt-2">Edit this info</Link>
                        </div>
                        <div className="col-md-8">
                            <div className="">
                                <h5 className="text-left mt-3 mb-3">{writer.name ? writer.name : 'no name available'}</h5>
                            </div>
                            <p className="mb-2"><u> Title:</u> <br /> {writer.title ? writer.title : "no info available"} </p>
                            <p><u> Description:</u> <br /> {writer.description ? writer.description : "no description available"} </p>
                            <div className="text-center">
                                <CopyToClipboard text={writer.id && writer.id}>
                                    <button className="btn btn-dark btn-sm mt-2">CopyID: {writer.id}</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}