import React, { useEffect, useState } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { userInfoCollectionRef } from '../../firebase/fire-app';
import { useForm } from "react-hook-form";
import { useAuth } from '../../Hooks/useAuth';
import AdminDetailsPopup from './AdminDetailsPopup';

const PageAdmins = () => {
    const [admins, loading, error] = useDocumentData(userInfoCollectionRef.doc('admins'));
    const {currentUser} = useAuth();
    const [adminEmails, setAdminEmails] = useState([]);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const logBtn = () => {
        alert(adminEmails);
    }
    useEffect(() => {
        // userInfoCollectionRef.doc('admins').get().then((doc) => {
        //     const admins = doc.data();
        //     setAdminEmails(admins.admins)
        // })
        if (admins) {
            if (admins.admins) {
                setAdminEmails(admins.admins)
            }
        }

    }, [admins])
    useEffect(()=>{
        if(admins){
            if(admins.superAdmins.indexOf(currentUser.email) > -1){
                setIsSuperAdmin(true)
            }
        }
    },[currentUser, admins])

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data, e) => {
        const allEmails = [...admins.pendingAdmins, data.email];
        const uniq = [...new Set(allEmails)];
        userInfoCollectionRef.doc('admins').update({
            pendingAdmins: uniq
        }).then(() => {
            alert('New admin added!');
            e.target.reset();
        })
        // alert(JSON.stringify(uniq))
    }
    const approveAdmin =(email)=>{
        const pendingEmails = admins.pendingAdmins;
        const newPendingEmails = pendingEmails.filter(x => x !== email);

        const allEmails = [...admins.admins, email];
        const uniq = [...new Set(allEmails)];

        userInfoCollectionRef.doc('admins').update({
            admins: uniq,
            pendingAdmins: newPendingEmails
            
        }).then(() => {
            alert('New admin added!');
        })
    }
    return (
        <>
            {/* <button className="btn btn-danger" onClick={logBtn}>Log</button> */}
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="mb-">
                                <h4 className="text-left mb-3 ">Super Admins</h4>
                                <div className="row">
                                    {
                                        admins && admins.superAdmins.map(item => <SuperAdminCard data={item} />)
                                    }
                                </div>
                            </div>
                            <div className="mb-4">
                                <h4 className="text-left mb-3 ">Admins</h4>
                                <div className="row">
                                    {
                                        adminEmails && adminEmails.map(item => <AdminCard data={item} />)
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                            <div className="">
                                <h4 className="text-left mb-3 ">Admins (Pending approval)</h4>
                                <div className="row">
                                    {
                                        admins && admins.pendingAdmins.map(item => <PendingAdminCard approveAdmin={approveAdmin} isSuperAdmin={isSuperAdmin} data={item} />)
                                    }
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="row">
                                    <div className="col-md-10 ">
                                        <div className="row">
                                            <InputWrapper col="8" label="Email">
                                                <input {...register("email")} type="email" name="email" className="form-control" />
                                            </InputWrapper>

                                            <div className="col-3">
                                                <button className="btn btn-primary" type="submit" >Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>



        </>
    );
};

export default PageAdmins;

const AdminCard = ({ data }) => {
    return (
        <>
            <div className="col-md-10 mb-4">
                <div className='bg-secondary user-meta-card'>
                    <h6 className="font-bold text-white pl-4">{data}</h6>
                </div>
            </div>
        </>
    )
}
const SuperAdminCard = ({ data }) => {
    return (
        <>
            <div className="col-md-10 mb-4">
                <div className='bg-primary  user-meta-card'>
                    <h6 className="font-bold text-white pl-4">{data}</h6>
                </div>
            </div>
        </>
    )
}
const PendingAdminCard = ({ data, isSuperAdmin, approveAdmin }) => {
    return (
        <>
            <div className="col-md-6 mb-4">
                <div className='pl-3  user-meta-card'>
                    <p className="font-bold mb-2">{data}</p>
                    {isSuperAdmin ? 
                    <button onClick={()=> approveAdmin(data)} className="btn btn-sm btn-danger">APPROVE</button>
                    :
                    <button disabled className="btn disabled btn-sm btn-danger">APPROVE</button>
                    }
                    {isSuperAdmin && <AdminDetailsPopup approveAdmin={approveAdmin} email={data}/>}
                </div>
            </div>
        </>
    )
}

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{label} </span>
            </div>
            {children}
        </div>
    );
};