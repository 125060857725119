export const timeStampToDate =(timeStamp)=>{
    if(timeStamp){
        const milliseconds =timeStamp.seconds * 1000
        const tt = new Date(milliseconds)
        const dateString =  tt.toDateString()
        return dateString;
    }
}

export const customDateFormat = (utcDate) => {
    if (utcDate) {
        let today = new Date(utcDate.seconds * 1000);
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        // today = mm + '-' + dd + '-' + yyyy;
        // console.log(today);
        // today = mm + '/' + dd + '/' + yyyy;
        // console.log(today);
        // today = dd + '-' + mm + '-' + yyyy;
        // console.log(today);
        today = dd + '/' + mm + '/' + yyyy;
        return today
    }else{
        return "Not yet"
    }
}

