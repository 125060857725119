import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { send } from "emailjs-com"


const UserDetailsPopup = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const onHideModal = () => {
        setShowModal(!showModal);
    }

    const notifyUser = () => {
        alert('request started')
        const userData = {
            name: data.fullName,
            email: data.userEmail
        }
        send('service_cqswsdo', 'template_lsys42e', userData, 'G-4jKP2cBtVGSifKg').then((res) => {
            alert("Thank you! We will reach you soon! ");
        })

    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={onHideModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-little-vcenter'>
                        User Details: {data.fullName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row user-popup">
                        <div className="col-md-5 offset-md-1">
                            <h6 className="font-bold">{data.fullName}</h6>
                            <p className="text-secondary font-15">{data.jobTitle}, {data.organization}</p>
                            <p className="text-secondary font-15">{data.country}</p>

                            <p className="font-15 mt-3">Job title: {data.jobTitle}</p>
                            <p className="font-15">Organization: {data.organization}</p>
                            <p className="font-15">Country: {data.country}</p>

                            <p className="font-15 mt-3">Phone: {data.userPhone}</p>
                            <p className="font-15">Email: {data.userEmail}</p>

                            <p className="font-15 mt-3 ">Area of specialization: {data.areaSpecialization}</p>
                            <p className="font-15 ">Area of Interest: {data.areaInterest}</p>
                        </div>
                        <div className="col-md-5">
                        

                            <h6 className="font-15 ">Reference Person: {data.refName}</h6>
                            <p className="font-15">Job title: {data.refJobTitle}</p>
                            <p className="font-15">Organization: {data.refOrganization}</p>
                            <p className="font-15 mb-5">Email: {data.refEmail}</p>
                            <button onClick={notifyUser} className="btn btn-sm mr-3 btn-danger">REQUEST MORE INFO</button>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Button onClick={() => setShowModal(true)} size="sm">DETAILS</Button>
        </>
    );
};

export default UserDetailsPopup;