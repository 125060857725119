import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import{ useState } from 'react';
import { blogsCollectionRef, insightsCollectionRef } from '../../firebase/fire-app';

const DeleteInsight = ({blog}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onDeleteBlog=()=>{
        insightsCollectionRef.doc(blog.id).delete().then(()=>{
            setIsModalVisible(false)
        })
    }

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <button className="btn btn-danger btn-sm" onClick={showModal} danger>
                Delete post
            </button>
            {blog && <Modal title={`${blog.title} - - - Delete?`} visible={isModalVisible} width={800} onOk={handleOk} onCancel={handleCancel}>
                <h3>{blog.title} </h3>
                <p><i>Category: {blog.category} </i> </p>
                <div className="mt-3 text-center">
                    <button onClick={onDeleteBlog} className="btn-danger btn btn-lg">Delete now</button>
                </div>
            </Modal>}
        </>
    );
};

export default DeleteInsight;