import { Link } from "react-router-dom";
import { partnersCollectionRef } from "../../firebase/fire-app";
import "./style.scss";

const PartnerCard = ({ data }) => {
//     const siteUrl = `https://theworldofpeace.vercel.app`;
//     const cardLogo = siteUrl + data.logo;
    // data.logo = cardLogo;
    const submitPartner = () => {
        // const partnerInfo = { ...data, logo: cardLogo };
        // partnerInfo.createdAt = new Date();
        // partnerInfo.country = "Bangladesh"
        // partnersCollectionRef.add(partnerInfo).then(res =>{
        //     console.log("added");
        // })

    }
    return (
        <>
            <div className="col-md-4 mb-4">

                {/* <Link href={`/partner/${data.slug}`} passHref> */}
                <div className="partner-card isClickable px-4 py-5">
                    <div className=" mb-3 ">
                        <div className="row">
                            <div className="col-md-9">
                            <h5 className="font-bold font-26">{data.name}</h5>

                            </div>
                            <div className="col-md-3">
                            {data.logo && <img className="img-fluid"  alt="Landscape picture" src={data.logo} />
                        }
                            </div>
                        </div>

                    </div>
                    <p className="font-18 mb-3">
                        {data.brief}
                    </p>
                    {/* <Link href={"/partner/" + data.slug} passHref>
                            <a href="#" className="btn btn-outline-primary">View Details</a>
                        </Link> */}
                    <Link to={"/partners/edit/"+data.id} className="btn btn-primary">Edit Info</Link>
                    {/* <button onClick={submitPartner} className="btn btn-primary">Add Now!</button> */}
                </div>
                {/* </Link> */}
            </div>
        </>
    )
}

export default PartnerCard;