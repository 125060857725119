import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import fireApp, { adminRegistrationCollectionRef, userInfoCollectionRef } from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';


const SuccessRegistrationPopup = () => {
    const [showModal, setShowModal] = useState(true);
    const onHideModal = () => {
        setShowModal(!showModal);
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={onHideModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-little-vcenter'>
                        Registration Done!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-5 py-4">
                        <h4>
                            Your registration is successful! But, you are not approved as an admin yet :'()'
                        </h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SuccessRegistrationPopup;