import { Layout } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import AntdMenu from "./AntdMenu";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,

} from '@ant-design/icons';



const { Content, Footer, Header } = Layout


const AntdLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(true)
    let x = window.matchMedia("(min-width: 756px)");
    const { currentUser } = useAuth()

    useEffect(() => {
        if (x.matches) {
            setCollapsed(false)
        } else {
            setCollapsed(true)
        }
    }, [])
    console.log(x.matches)

    const toggle = () => {
        setCollapsed(!collapsed);
    }
    return (
        <>
            <Layout>
                <AntdMenu collapsed={collapsed} setCollapsed={setCollapsed}  />
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        <div className="d-none d-md-block">
                            {
                                collapsed ? <MenuUnfoldOutlined onClick={toggle} className="trigger" /> : <MenuFoldOutlined onClick={toggle} className="trigger" />
                            }
                            <span className="mr-auto d-inline-block">
                                {currentUser && currentUser.name}
                            </span>
                        </div>
                    </Header>
                    <Content style={{ margin: "24px 16px 0" }}>
                        <div className="site-layout-background" style={{ padding: 16, minHeight: 360 }} >
                            {children}
                        </div>
                    </Content>
                    <Footer style={{ textAlign: "center" }}>&copy; theworldofpeace.org | All Rights Reserved</Footer>
                </Layout>
            </Layout>
        </>
    );
};

export default AntdLayout;