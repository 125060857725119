import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import fireApp, { writersCollectionRef } from '../../firebase/fire-app';

const AddWriter = () => {
    const writerDoc = writersCollectionRef.doc();
    const [writerId, setWriterId] = useState(writerDoc.id);
    const { register, handleSubmit, watch, errors } = useForm();
    const [userImg, setUserImg] = useState(null)
    const onWriterAdded = (data, e) => {
        const writerName = data.name.toLowerCase();
        data.userName= writerName.replace(/ /g,"-");
        data.img = userImg? userImg: null;
        console.log(data);
        writersCollectionRef.doc(writerId).set(data).then(()=>{
            e.target.reset();
            setWriterId(null);
            setUserImg(null);
        })
    }

    const onUserImgUploaded = (e) => {
        let storageRef = fireApp.storage().ref();
        let blogStorage = storageRef.child(`writers/${writerId}`);
        const imgFile = e.target.files[0];
        blogStorage
            .put(imgFile).then((snapshot) => {
                snapshot.ref.getDownloadURL().then(res => {
                    setUserImg(res);
                })
            })
    }
    return (
        <>
            <section className="bg-white">
                <div className="container-fluid">
                    <form className="p-5" onSubmit={handleSubmit(onWriterAdded)}>

                        <Row>
                            <InputWrapper col={5} label="Full Name" >
                                <input {...register("name")} type="text" name="name" className="form-control" placeholder="Sundar Pichai" />
                            </InputWrapper>
                            <InputWrapper col={7} label="Title">
                                <input {...register("title")} type="text" name="title" className="form-control" placeholder="CEO, Google" />
                            </InputWrapper>
                            <InputWrapper col={12} label="Description">
                                <input {...register("description")} type="text" name="description" className="form-control" placeholder="short description here..." />
                            </InputWrapper>

                            <InputWrapper col={5} label="Image (1:1)">
                                <input type="file" className="form-control" onChange={onUserImgUploaded} />
                            </InputWrapper>
                            <div className="col-md-7"></div>
                            <div className="col-md-2">
                                {userImg && <img src={userImg} alt="" className="img-fluid" />}
                            </div>
                            <div className="col-12 mt-4"><h5> Social Links: </h5></div>
                            <InputWrapper col={6} label="Facebook">
                                <input {...register("socialLinks.fb")} type="text" name="socialLinks.facebook" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col={6} label="LinkedIn">
                                <input {...register("socialLinks.linkedIn")} type="text" name="socialLinks.linkedIn" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col={6} label="Twitter: ">
                                <input {...register("socialLinks.twitter")} type="text" name="socialLinks.twitter" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col={6} label="Email: ">
                                <input {...register("socialLinks.email")} type="text" name="socialLinks.email" className="form-control" />
                            </InputWrapper>

                            <div className="col-12 text-center">
                                <Button type="submit">Add New Writer</Button>
                            </div>
                        </Row>

                    </form>
                </div>
            </section>
        </>
    );
};

export default AddWriter;


const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};