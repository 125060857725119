import { Modal, Button } from 'antd'
import { useEffect, useRef, useState } from 'react';
import { useBlogs } from '../../Hooks/useBlogs';
import { Tag, Input } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';
import { websiteDataRef } from '../../firebase/fire-app';

const AddNewCategory = () => {
    const inputRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const { categories } = useBlogs();
    const [processing, setProcessing] = useState(false)

    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("")

    useEffect(() => {
        if (categories) {
            if (categories.newsCategories) {
                setTags(categories.newsCategories)
            }
        }
    }, [categories])
    const handleClose = removedTag => {
        const updatedTags = tags.filter(tag => tag !== removedTag);
        setTags([...updatedTags])
    }
    const showInput = () => {
        setInputVisible(true);
        // inputRef.current.focus();
    };
    const handleInputChange = e => {
        setInputValue(e.target.value)
    };
    const onModalShow = () => {
        setShowModal(!showModal)
    }
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags( [...tags, inputValue])
        }
        console.log(tags);
        setInputVisible(false);
        setInputValue("")

    };
    const onUpdateCategories =()=>{
        console.log("tags", tags);
        setProcessing(true)
        websiteDataRef.doc('categories').update({
            newsCategories: tags
        }).then(()=>{
            setProcessing(false);
            setShowModal(false)
        })
    }

    return (
        <>
            <Button type="primary" onClick={onModalShow} >Add new Category</Button>

            <Modal title="Blog Categories" visible={showModal} onOk={onModalShow} onCancel={onModalShow}
            footer={[
                <Button key="back" onClick={showModal}>
                  Return
                </Button>,
                <Button key="submit" type="primary" loading={processing} onClick={onUpdateCategories}>
                  Submit
                </Button>,
                
              ]}>
                <div style={{ marginBottom: 16 }}>
                    <TweenOneGroup
                        enter={{
                            scale: 1,
                            opacity: 0,
                            type: 'from',
                            duration: 100,
                            onComplete: e => {
                                e.target.style = '';
                            },
                        }}
                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                        appear={false}
                    >
                        {
                            tags.map(tag => <span  key={tag} style={{ display: 'inline-block' }}>
                                <Tag
                                className="mt-1 p-1 px-2"
                                    closable
                                    onClose={e => {
                                        e.preventDefault();
                                        handleClose(tag);
                                    }}
                                >
                                    {tag}
                                </Tag>
                            </span>)
                        }
                    </TweenOneGroup>
                </div>
                {inputVisible && (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              style={{ width: 140 }}
              value={inputValue}
              placeholder="write here ..."
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )}
          {!inputVisible && (
            <Tag onClick={showInput} className="site-tag-plus">
              <PlusOutlined /> New category
            </Tag>
          )}
            </Modal>
        </>
    );
};

export default AddNewCategory;



// class EditableTagGroup extends React.Component {
//     state = {
//       tags: ['Tag 1', 'Tag 2', 'Tag 3'],
//       inputVisible: false,
//       inputValue: '',
//     };

//     handleClose = removedTag => {
//       const tags = this.state.tags.filter(tag => tag !== removedTag);
//       console.log(tags);
//       this.setState({ tags });
//     };

//     showInput = () => {
//       this.setState({ inputVisible: true }, () => this.input.focus());
//     };

//     handleInputChange = e => {
//       this.setState({ inputValue: e.target.value });
//     };

//     handleInputConfirm = () => {
//       const { inputValue } = this.state;
//       let { tags } = this.state;
//       if (inputValue && tags.indexOf(inputValue) === -1) {
//         tags = [...tags, inputValue];
//       }
//       console.log(tags);
//       this.setState({
//         tags,
//         inputVisible: false,
//         inputValue: '',
//       });
//     };

//     saveInputRef = input => {
//       this.input = input;
//     };

//     forMap = tag => {
//       const tagElem = (
//         <Tag
//           closable
//           onClose={e => {
//             e.preventDefault();
//             this.handleClose(tag);
//           }}
//         >
//           {tag}
//         </Tag>
//       );
//       return (
//         <span key={tag} style={{ display: 'inline-block' }}>
//           {tagElem}
//         </span>
//       );
//     };

//     render() {
//       const { tags, inputVisible, inputValue } = this.state;
//       const tagChild = tags.map(this.forMap);
//       return (
//         <>
//           <div style={{ marginBottom: 16 }}>
//             <TweenOneGroup
//               enter={{
//                 scale: 0.8,
//                 opacity: 0,
//                 type: 'from',
//                 duration: 100,
//                 onComplete: e => {
//                   e.target.style = '';
//                 },
//               }}
//               leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
//               appear={false}
//             >
//               {tagChild}
//             </TweenOneGroup>
//           </div>
//           {inputVisible && (
//             <Input
//               ref={this.saveInputRef}
//               type="text"
//               size="small"
//               style={{ width: 78 }}
//               value={inputValue}
//               onChange={this.handleInputChange}
//               onBlur={this.handleInputConfirm}
//               onPressEnter={this.handleInputConfirm}
//             />
//           )}
//           {!inputVisible && (
//             <Tag onClick={this.showInput} className="site-tag-plus">
//               <PlusOutlined /> New Tag
//             </Tag>
//           )}
//         </>
//       );
//     }
//   }