import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { partnersCollectionRef } from "../../firebase/fire-app";

import RichTextEditor from "../programs/RichTextEditor";
import { useHistory } from 'react-router-dom';

const EditPartnerInfo = () => {
    const { partnerID } = useParams();
    const [loading, setLoading] = useState(true);
    const [partnerData, setPartnerData] = useState(null);
    const history = useHistory();

    const [descriptionOne, setDescriptionOne] = useState('');
    const [descriptionTwo, setDescriptionTwo] = useState('');

    useEffect(() => {
        if (partnerData) {
            setDescriptionOne(partnerData.descriptionOne);
            setDescriptionTwo(partnerData.descriptionTwo);
        }
    }, [partnerData])


 

    useEffect(() => {
        if (partnerID) {
            partnersCollectionRef.doc(partnerID).get().then((doc) => {
                setPartnerData(doc.data());
                setLoading(false)
            })
        }
    }, [partnerID])
    const updateProgramData = () => {
        const updatedInfo = {
            country: partnerData.country,
            name: partnerData.name,
            logo: partnerData.logo,
            slug: partnerData.slug,
            descriptionOne: descriptionOne,
            descriptionTwo: descriptionTwo,
        }
        partnersCollectionRef.doc(partnerID).set(updatedInfo).then(() => {
            alert('updated!')
        })
    }
    const deleteProgram = () => {
        partnersCollectionRef.doc(partnerID).delete().then(() => {
            history.push("/partners")

        })
    }

    return (
        <>
            {/* <h4 className="font-bold">{partnerID}</h4> */}
            {partnerData && <div className="container-fluid">
                <div className="row mb-4 d-flex align-items-center">
                    <div className="col-md-1">
                        <img src={partnerData.logo} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-5">
                        <h4 className="font-bold">{partnerData.name} </h4>

                    </div>

                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <RichTextEditor title='Info Part One' defaultText={partnerData.descriptionOne} returnText={setDescriptionOne} />
                    </div>
                    <div className="col-md-6">
                        <RichTextEditor title='Info Part Two' defaultText={partnerData.descriptionTwo} returnText={setDescriptionTwo} />
                    </div>
                    <div className="col-12 mt-4 text-center">
                        <button onClick={updateProgramData} className="btn btn-primary">Update Program Info</button>
                    </div>
                </div>
            </div>}
            {/* <p>{briefData}</p> */}
        </>
    );
};

export default EditPartnerInfo;

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{label} </span>
            </div>
            {children}
        </div>
    );
};

