import { Button, Radio } from "antd";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useBlogs } from "../../Hooks/useBlogs";
import NewsContentCard from "./NewsConentCard";


const PageNews = () => {
    const { news, categories, loading } = useBlogs();
    const [showBlogs, setShowBlogs] = useState(null);
    const [category, setCategory] = useState(null);

    useEffect(() => {
        if (news) {
            setShowBlogs(news)
        }

    }, [news])
    useEffect(() => {
        if (news && category) {
            const categorized = news.filter(item => item.category === category);
            setShowBlogs([...categorized])
        }
    }, [category])

    const onCategoryChange = (e) => {
        setCategory(e.target.value)
    }
    return (
        <>
            {/* <div className="jumbotron text-center">
                <h2>Blogs</h2>
            </div> */}
            <div className="container-fluid mb-4">
                <div className="row">
                    <div className="col-md-9">
                        <Radio.Group onChange={onCategoryChange}>
                            {categories && categories.newsCategories.map(item => <Radio.Button type="primary" value={item}>{item} </Radio.Button>)}
                        </Radio.Group>
                    </div>
                    <div className="col-md-3">
                        {category && <h5 className="text-center"><small>Category: </small> {category} </h5>}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    {showBlogs && showBlogs.map(item => <NewsContentCard key={item.id} blog={item} />)}
                </div>
            </div>
        </>
    );
};

export default PageNews;

const CommonContentCard = ({ blog }) => {
    return (
        <>
            {blog ? <div className="col-md-12 mb-4">
                <div className="card p-4 ">
                    <div className="row">
                        <div className="col-md-4 border-right">
                            <h5>{blog.title} </h5>
                            <p><i>Created at: 10/20/2021</i></p>
                            <div className="mt-3">
                                <Link to={`/blog/${blog.id}`} className="btn btn-primary mr-3 btn-sm">View</Link>
                                <Link to={`/blog/edit/${blog.id}`} className='btn btn-warning mr-3 btn-sm'>Edit</Link>
                                <button className="btn btn-danger btn-sm">Delete</button>
                            </div>
                        </div>
                        <div className="col-md-4">
                            category
                    </div>
                        <div className="col-md-3">
                            <img src={blog.featuredImage} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div> : <Spinner animation='grow' variant='primary' />}
        </>
    )
}