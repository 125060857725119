import { Modal, Button } from 'antd'
import {  useRef, useState } from 'react';
import { useBlogs } from '../../Hooks/useBlogs';
import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {  writersCollectionRef } from '../../firebase/fire-app';
import {  useDocumentData } from 'react-firebase-hooks/firestore';
import { MdDeleteForever} from "react-icons/md"

const EditWriters = ({ writers, setWriters }) => {
    const inputRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const { categories } = useBlogs();
    const [processing, setProcessing] = useState(false)

    const [tags, setTags] = useState(writers);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("")


    const handleClose = removedTag => {
        const updatedTags = tags.filter(tag => tag !== removedTag);
        setTags([...updatedTags])
    }
    const showInput = () => {
        setInputVisible(true);
        // inputRef.current.focus();
    };
    const handleInputChange = e => {
        setInputValue(e.target.value)
    };
    const onModalShow = () => {
        setShowModal(!showModal)
    }
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }
        console.log(tags);
        setInputVisible(false);
        setInputValue("")

    };
    const onUpdateCategories = () => {
        console.log("tags", tags);
        setProcessing(true)
        setWriters(tags);
        setProcessing(false);
        setShowModal(false)
        // websiteDataRef.doc('categories').update({
        //     blogCategories: tags
        // }).then(() => {
        //     setProcessing(false);
        //     setShowModal(false)
        // })
    }
    return (
        <>
            <Button type="primary" onClick={onModalShow} >View Writers</Button>

            <Modal title="Blog Categories" width={1000} visible={showModal} onOk={onModalShow} onCancel={onModalShow}
                footer={[
                    <Button key="back" onClick={showModal}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={processing} onClick={onUpdateCategories}>
                        Submit
                    </Button>,
                ]}>
                {!inputVisible && (
                    <Tag onClick={showInput} className="site-tag-plus">
                        <PlusOutlined /> New writer
                    </Tag>
                )}
                {inputVisible && (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        style={{ width: 140 }}
                        value={inputValue}
                        placeholder="put writer id here ..."
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                )}
                <div className="w-100 mt-3">
                    <div className="row">
                        {
                            tags.map(tag =>
                                <WriterCard handleClose={handleClose} id={tag} />
                            )
                        }
                    </div>
                </div>
                

            </Modal>
        </>
    );
};

export default EditWriters;

const WriterCard = ({ id, handleClose }) => {
    const [writer, loading, error] = useDocumentData(writersCollectionRef.doc(id), { idField: "id" })
    return (
        <>
            {writer && <div className="col-md-6 mb-3">
                <div className="card p-3">
                    {/* <h5>{id} </h5> */}
                    <div className="row">
                        <div className="col-md-4">
                            <img src={writer.img && writer.img} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-8">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-left mt-3 mb-3">{writer.name ? writer.name : 'no name available'}</h5>
                                <button onClick={()=> handleClose(id)} class="delete-button touch delete"><MdDeleteForever/></button>

                            </div>

                            <p className="mb-2"><u> Title:</u> <br /> {writer.title ? writer.title : "no info available"} </p>
                            <p><u> Description:</u> <br /> {writer.description ? writer.description : "no description available"} </p>
                            <div className="text-center">

                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}