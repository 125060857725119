import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import fireApp, { adminRegistrationCollectionRef, userInfoCollectionRef } from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import SuccessRegistrationPopup from "./SuccessRegistrationPopup";
import { usePasswordValidation } from "../../Hooks/usePasswordValidation";



const AdminRegisterPopup = () => {
    // password validation
    const [password, setPassword] = useState({
        firstPassword: "",
        secondPassword: "",
    });
    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
        match,
        specialChar,
        validPassword,
    ] = usePasswordValidation({
        firstPassword: password.firstPassword,
        secondPassword: password.secondPassword,
    });

    const setFirst = (event) => {
        setPassword({ ...password, firstPassword: event.target.value });
    };
    const setSecond = (event) => {
        setPassword({ ...password, secondPassword: event.target.value });
    };

    // password validation done

    const [showModal, setShowModal] = useState(false);
    const [admins, loading, error] = useDocumentData(userInfoCollectionRef.doc('admins'));
    const [showSuccess, setShowSuccess] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { currentUser, setCurrentUser, setHasAccess, handleGoogleSignIn, signOut, basicLog } = useAuth();




    const onHideModal = () => {
        setShowModal(!showModal);
    }


    const onFormSubmit = (data, e) => {

        const email = data.email;
        const userPass = password.firstPassword;



        fireApp.auth().createUserWithEmailAndPassword(email, userPass).then((userCredential) => {
            alert(JSON.stringify(userCredential.user));
            const user = userCredential.user;
            const userData = { uid: user.uid, ...data };

            adminRegistrationCollectionRef.doc(userData.uid).set(userData).then(() => {
                setCurrentUser(userData);
            })
            const allEmails = [...admins.pendingAdmins, data.email];
            const uniq = [...new Set(allEmails)];
            userInfoCollectionRef.doc('admins').update({
                pendingAdmins: uniq
            }).then(() => {
                e.target.reset();
                setHasAccess(false);
                setShowModal(false);
                setShowSuccess(true);
            })
            // setRegistered(true);
        })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage);
            })
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={onHideModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-little-vcenter'>
                        Reset Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <form onSubmit={handleSubmit(onFormSubmit)} >
                                <div className="mb-3">
                                    <InputWrapperComponent required htmlFor={"name"} label="Name">
                                        <input {...register("name", { required: true })} type="text" className="form-control" name="name" id="name" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"email"} label="Email:">
                                        <input {...register("email", { required: true })} type="email" className="form-control" name="email" id="email" />
                                    </InputWrapperComponent>
                                    {/* <InputWrapperComponent required htmlFor={"password"} label="Password:">
                                        <input {...register("password", { required: true })} type="password" className="form-control" name="password" id="password" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"password"} label="Password:">
                                        <input {...register("password", { required: true })} type="password" className="form-control" name="password" id="password" />
                                    </InputWrapperComponent> */}
                                    <InputWrapperComponent required htmlFor={"firstPass"} label="Password:">
                                        <input type="password" onChange={setFirst} className="form-control" name="firstPass" id="firstPass" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"secondPass"} label="Retype Password:">
                                        <input type="password" onChange={setSecond} className="form-control" name="secondPass" id="secondPass" />
                                    </InputWrapperComponent>
                                    <div className="row">
                                        <div className="col-md-9 offset-md-3">
                                            <ul>
                                                <li>
                                                    Length(8): {validLength ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>
                                                <li>
                                                    Numeric value: {hasNumber ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>
                                                <li>
                                                    UpperCase: {upperCase ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>
                                                <li>
                                                    LowerCase: {lowerCase ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>
                                                <li>
                                                    Special Character:{" "}
                                                    {specialChar ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>
                                                <li>Match both: {match ? <span className="text-success">True</span> : <span className="text-danger">False</span>}</li>
                                        
                                            </ul>
                                        </div>
                                    </div>
                                    <InputWrapperComponent required htmlFor={"phone"} label="Phone:">
                                        <input {...register("phone", { required: true })} type="text" className="form-control" name="phone" id="phone" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"organization"} label="Organization">
                                        <input {...register("organization", { required: true })} type="text" className="form-control" name="organization" id="organization" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"designation"} label="Designation">
                                        <input {...register("designation", { required: true })} type="text" className="form-control" name="designation" id="designation" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"country"} label="Country">
                                        <input {...register("country", { required: true })} type="text" className="form-control" name="country" id="country" />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent required htmlFor={"projects"} label="Affiliated Projects">
                                        <input {...register("projects", { required: true })} type="text" className="form-control" name="projects" id="projects" />
                                    </InputWrapperComponent>
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3">
                                            {validLength && hasNumber && upperCase && specialChar && match ? <button type="submit" className="btn btn-primary mb-4 mr-3">Register!</button> :
                                                <button type="submit" disabled className="btn btn-primary mb-4 mr-3">Register</button>}
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* <SuccessRegistrationPopup showSuccess={showSuccess} setShowSuccess={setShowSuccess}/> */}
            <button onClick={() => setShowModal(true)} className="btn btn-sm btn-primary">Register Now!</button>
        </>
    );
};

export default AdminRegisterPopup;

const InputWrapperComponent = ({ children, label, htmlFor, required }) => {
    return (
        <div className="mb-3 row">
            <label htmlFor={htmlFor} className="col-sm-3 col-form-label">{label} {required && <span className="text-danger font-bold">*</span>}</label>
            <div className="col-sm-9">
                {children}
            </div>
        </div>
    )
}