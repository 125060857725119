import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

const ResourceNavbar = ({ setActive, pubTitle }) => {
    const [resType, setResType] = useState('ALL')

    const onTypeChange=(data)=>{
        setResType(data);
        setActive(data);
    }
    return (
        <>
            <section className="bg-light py-4">

                <div className="container">
                    <button onClick={() => onTypeChange('ALL')} className={`btn ${resType === 'ALL' ? "btn-primary" : "btn-outline-primary"} btn-filter mr-3`}>
                    ALL
                    </button>
                    <button onClick={() => onTypeChange('REPORTS')} className={`btn ${resType === 'REPORTS' ? "btn-primary" : "btn-outline-primary"} btn-filter mr-3`}>
                    REPORTS
                    </button>
                    <button onClick={() => onTypeChange('MODULES')} className={`btn ${resType === 'MODULES' ? "btn-primary" : "btn-outline-primary"} btn-filter mr-3`}>
                    MODULES
                    </button>
                    <button onClick={() => onTypeChange('IEC_MATERIALS')} className={`btn ${resType === 'IEC_MATERIALS' ? "btn-primary" : "btn-outline-primary"} btn-filter mr-3`}>
                        IEC_MATERIALS
                    </button>
                    <button onClick={() => onTypeChange('NEWSLETTER')} className={`btn ${resType === 'NEWSLETTER' ? "btn-primary" : "btn-outline-primary"} btn-filter mr-3`}>
                        NEWSLETTER
                    </button>


                </div>
            </section>
        </>
    );
};

export default ResourceNavbar;