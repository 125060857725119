import { BrowserRouter as Router } from 'react-router-dom';
import { BlogsContextProvider } from './Hooks/useBlogs';
import { AuthContextProvider } from './Hooks/useAuth';
import Main from './Pages/Main';

const App = () => {
  return (
    <>
    <AuthContextProvider>
      <BlogsContextProvider>
        <Router>
          <Main />
        </Router>
      </BlogsContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
