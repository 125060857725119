export const categorizedInsightsIndustries =[
    `Banking & Leasing`,
    `Business Process Outsourcing`,
    `Business Strategy`,
    `Capital Market`,
    `Change Management`,
    `Consumer Goods & Services`,
    `Customer Experience`,
    `Data Analytics`,
    `Energy`,
    `Finance Consulting`,
    `Government & Non-profit`,
    `Healthcare`,
    `Insurance`,
    `Marketing`,
    `Mergers & Acquisitions`,
    `Miscellaneous`,
    `Natural Resources`,
    `Pharmaceuticals`,
    `Retail`,
    `Supply Chain Management`,
    `Travel`,
    `Utilities`,
]

export const categorizedInsightsResources =[
    `Reports`,
    `Fin5`,
    `This Week In Business`,
]

export const updatedInsightCategories =[
    {
        id: 0,
        name: `Industries & Services`,
        categories: [
            `Banking & Leasing`,
            `Business Process Outsourcing`,
            `Business Strategy`,
            `Capital Market`,
            `Change Management`,
            `Consumer Goods & Services`,
            `Customer Experience`,
            `Data Analytics`,
            `Energy`,
            `Finance Consulting`,
            `Government & Non-profit`,
            `Healthcare`,
            `Insurance`,
            `Marketing`,
            `Mergers & Acquisitions`,
            `Miscellaneous`,
            `Natural Resources`,
            `Pharmaceuticals`,
            `Retail`,
            `Supply Chain Management`,
            `Travel`,
            `Utilities`,
        ],
    },
    {
        id: 1,
        name: `Resources`,
        categories: [
            `Reports`,
            `Fin5`,
            `This Week In Business`,
            `Market Updates`,
        ],
    }
]